import React from "react";
import ContactUs from "../components/contacts/ContactUs";
import Social from "../components/contacts/Socials";
import WriteUs from "../components/contacts/WriteUs";
import { useSelector } from "react-redux";

const styles = {
  textAlign: "center",
  fontWeight: "100",
  marginTop: "50px",
};

const contactSectionStyles = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
}

const lang = {
  EN: {
    title: "Contact"
  },
  GE: {
    title: "კონტაქტი"
  }
}

const Contact = () => {
  window.scrollTo(0, 0);
  const active = useSelector((state) => state.activeLanguage.lang);
  return (
    <div style={{ margin: "0px auto 70px auto", width: "90%" }} >
      <h2 style={styles}>{lang[active].title}</h2>
      <div style={contactSectionStyles} className="contact-responsive">
        <div style={{width: 'calc(50% - 10px)'}} className="set-responsive">
          <ContactUs />
          <Social />
        </div>
        <WriteUs />
      </div>
    </div>
  );
};

export default Contact;
