import { Fragment } from "react";
import { useSelector } from "react-redux";
import styles from "./AuctionHeader.module.css";
import languages from "../languages";

const AuctionHeader = () => {

  const activeLang = useSelector((state) => state.activeLanguage.lang)

  const vehicle = useSelector((state) => state.vehiclesData.data);

  return (
    <Fragment>
      {vehicle && (
        <div className={styles["vehicle-info-header-container"]}>
          <div className={styles["vehicle-mark-info"]}>
            <h3>{`${vehicle.attributes.name}`}</h3>
            <p>
              {languages[activeLang].auctionPage.location}: <span>{vehicle.attributes.city}</span>
            </p>
          </div>
          <div className={styles["vehicle-price-info"]}>
            <h2 className={styles["price"]}>
              {(vehicle.attributes.buy_now_price !== "0.00")
                ? vehicle.attributes.buy_now_price
                : vehicle.attributes.highest_bid}
            </h2>
            <div>
              {/* <button className={styles["gel-button"]}>ლ</button> */}
              <button className={styles["usd-button"]}>$</button>
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default AuctionHeader;
