import axios from "axios";
import { useState } from "react";
import styles from "./WriteUs.module.css";
import languages from "../languages";
import { useSelector } from "react-redux";

const WriteUs = () => {
  const activeLang = useSelector((state) => state.activeLanguage.lang);

  const [showSuccess, setShowSuccess] = useState(false);

  const [formData, setFormData] = useState({
    name: "",
    subject: "",
    email: "",
    message: "",
  });

  const nameInputKeypressHandler = (e) => {
    setFormData((prevState) => {
      return { ...prevState, name: e.target.value };
    });
  };

  const emailInputKeypressHandler = (e) => {
    setFormData((prevState) => {
      return { ...prevState, email: e.target.value };
    });
  };

  const subjectInputKeypressHandler = (e) => {
    setFormData((prevState) => {
      return { ...prevState, subject: e.target.value };
    });
  };

  const messageInputKeypressHandler = (e) => {
    setFormData((prevState) => {
      return { ...prevState, message: e.target.value };
    });
  };

  const contactFormSubmitHandler = async (e) => {
    e.preventDefault();

    try {
      axios.post("/contact/form", formData);
      setShowSuccess(true);
      setTimeout(() => {
        setShowSuccess(false);
      }, 4000);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div style={{ width: "calc(50% - 10px)" }} className="set-responsive">
      <h3 className={styles["section-title"]}>
        {languages[activeLang].contacts.messageUs}
      </h3>
      <div className={styles["write-us-container"]}>
        <div className={styles["write-us-content"]}>
          <form
            className={styles["write-us-form"]}
            onSubmit={contactFormSubmitHandler}
          >
            <div className={styles["form-field-content"]}>
              <label className={styles["label"]}>
                {languages[activeLang].contacts.name}
              </label>
              <input
                type="text"
                name="name"
                placeholder={languages[activeLang].contacts.name}
                onKeyUp={nameInputKeypressHandler}
                className={styles["form-control"]}
              />
            </div>
            <div className={styles["form-field-content"]}>
              <label className={styles["label"]}>
                {languages[activeLang].contacts.email}
              </label>
              <input
                type="email"
                name="email"
                placeholder={languages[activeLang].contacts.email}
                onKeyUp={emailInputKeypressHandler}
                className={styles["form-control"]}
              />
            </div>
            <div className={styles["form-field-content"]}>
              <label className={styles["label"]}>
                {languages[activeLang].contacts.subject}
              </label>
              <input
                type="text"
                name="subject"
                placeholder={languages[activeLang].contacts.subject}
                onKeyUp={subjectInputKeypressHandler}
                className={styles["form-control"]}
              />
            </div>
            <div className={styles["form-field-content"]}>
              <label className={styles["label"]}>
                {languages[activeLang].contacts.message}
              </label>
              <input
                type="text"
                name="message"
                placeholder={languages[activeLang].contacts.message}
                onKeyUp={messageInputKeypressHandler}
                className={styles["form-control"]}
              />
            </div>
            {showSuccess && (
              <p
                style={{
                  color: "green",
                  fontSize: "14px",
                  width: "90%",
                  margin: "0 auto",
                }}
              >
                {languages[activeLang].contacts.sent}
              </p>
            )}
            <button type="submit" className={styles["submit-button"]}>
              {languages[activeLang].contacts.send}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default WriteUs;
