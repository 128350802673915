const SafetyTransportation = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={`${props.iconWidth}px`}
      fill={props.color ? props.color : "black"}
      viewBox="0 0 47.831 47.831"
    >
      <g
        id="vuesax_linear_ship"
        dataname="vuesax/linear/ship"
        transform="translate(-435 -188)"
      >
        <g id="ship" transform="translate(435 188)">
          <path
            id="Vector"
            d="M36.056,6.108a4.141,4.141,0,0,1,2.411,4.564l-.817,3.707A13.43,13.43,0,0,1,24.018,25.3H14.532A13.43,13.43,0,0,1,.9,14.379L.083,10.672A4.141,4.141,0,0,1,2.494,6.108l2.83-1.136L16.306.568a8.046,8.046,0,0,1,5.939,0l10.981,4.4Z"
            transform="translate(4.64 18.544)"
            fill="none"
            stroke="#292d32"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="3"
          />
          <path
            id="Vector-2"
            dataname="Vector"
            d="M0,23.916V0"
            transform="translate(23.916 19.93)"
            fill="none"
            stroke="#292d32"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="3"
          />
          <path
            id="Vector-3"
            dataname="Vector"
            d="M27.9,5.979v7.573L16.92,9.148a8.046,8.046,0,0,0-5.939,0L0,13.552V5.979A6,6,0,0,1,5.979,0H21.923A6,6,0,0,1,27.9,5.979Z"
            transform="translate(9.965 9.965)"
            fill="none"
            stroke="#292d32"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="3"
          />
          <path
            id="Vector-4"
            dataname="Vector"
            d="M9.965,5.979H0V1.993A2,2,0,0,1,1.993,0H7.972A2,2,0,0,1,9.965,1.993Z"
            transform="translate(18.933 3.986)"
            fill="none"
            stroke="#292d32"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="3"
          />
          <path
            id="Vector-5"
            dataname="Vector"
            d="M0,0H47.831V47.831H0Z"
            fill="none"
            opacity="0"
          />
        </g>
      </g>
    </svg>
  );
};

export default SafetyTransportation;
