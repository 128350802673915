import { createSlice } from "@reduxjs/toolkit";

const initLanguages = {
    lang: "EN"
}

const activeLanguage = createSlice({
    name: 'activeLanguage',
    initialState: initLanguages,
    reducers: {
        changeLanguage(state, action){
            state.lang = action.payload
        }
    }
})

export const activeLanguageActions = activeLanguage.actions;

export default activeLanguage.reducer;