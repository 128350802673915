const Consultation = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="48"
      viewBox="0 0 57 63"
      fill="none"
    >
      <path
        d="M37.7778 25.2474C37.7783 22.8555 37.0899 20.5141 35.795 18.5031C34.5 16.4921 32.6532 14.8967 30.4755 13.9075V0.55835C37.8919 1.21025 41.7445 3.54635 45.1942 6.74358C45.3671 6.90612 45.54 7.06348 45.706 7.21564V7.21737C47.1758 8.56612 48.3447 9.63993 48.9482 11.2481L56.2695 30.7392C56.4657 31.2624 56.5322 31.8253 56.4632 32.3797C56.3942 32.9342 56.1919 33.4637 55.8735 33.9228C55.5551 34.382 55.1302 34.7571 54.6351 35.016C54.14 35.275 53.5895 35.4101 53.0308 35.4097H50.2105V39.4542L37.112 29.2815C37.5555 27.9834 37.781 26.6209 37.7795 25.2491L37.7778 25.2474ZM20.1005 13.9421V0.791788C4.55529 3.13135 0.382812 14.7289 0.382812 21.0818C0.382812 31.0539 6.75133 38.7885 9.7376 41.8889V62.6251H39.3271V51.3647H46.7504C47.2726 51.3647 47.7706 51.2471 48.2133 51.0396L29.9101 36.8259C26.8849 38.0743 23.4898 38.0813 20.4595 36.8455C17.4292 35.6097 15.0073 33.2305 13.7179 30.2226C12.4285 27.2146 12.3753 23.82 13.5698 20.7731C14.7643 17.7263 17.1104 15.2723 20.1005 13.9421Z"
        fill="black"
      />
    </svg>
  );
};

export default Consultation;
