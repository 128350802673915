import Facebook from '../svg/Facebook';
import Instagram from '../svg/Instagram';
import Youtube from '../svg/Youtube';
import styles from './SocialMedia.module.css';

const SocialMedia = () => {
    return(
        <div className={styles['social-media-container']}>
            <Facebook iconWidth="30" borderColor="#FD375F" />
            <Instagram iconWidth="30" borderColor="#FD375F" />
            <Youtube iconWidth="30" borderColor="#FD375F" />
        </div>
    )
}

export default SocialMedia;