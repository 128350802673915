import { createSlice } from "@reduxjs/toolkit";

const initialVehicle = {
    images: [],
    data: null,
    mainImage: null,
    popupIsOpened: false,
    auctionPage: [],
    next: null
}

const vehiclesData = createSlice({
    name: 'vehicle',
    initialState: initialVehicle,
    reducers: {
        changeImages (state, action) {
            state.images = action.payload
        },
        changeData(state, action){
            state.data = action.payload
        },
        changeMainImage(state, action){
            state.mainImage = action.payload
        },
        changePopupVisibility(state){
            state.popupIsOpened = !state.popupIsOpened
        },
        auctionPageHandler(state, action){
            // action.payload.forEach((el) => {
            //     state.auctionPage.push(el)
            // })
            state.auctionPage = state.auctionPage.concat(action.payload)
        },
        firstRequest(state, action){
            state.auctionPage = action.payload
        },
        changeNextPage(state, action){
            state.next = action.payload
        }
    }
})

export const vehicleDataActions = vehiclesData.actions;

export default vehiclesData.reducer