

const damages = {
    EN: [
        {text: "Good Condition", value: "GOOD"},
        {text: "Minor Damage", value: "MINOR_DAMAGE"},
        {text: "Damaged", value: "DAMAGE"}
    ],
    GE: [
        {text: "დაუზიანებელი", value: "GOOD"},
        {text: "მცირე დაზიანებით", value: "MINOR_DAMAGE"},
        {text: "დაზიანებული", value: "DAMAGE"}
    ],
    RU: [
        {text: "Хорошее состояние", value: "GOOD"},
        {text: "Незначительные повреждения", value: "MINOR_DAMAGE"},
        {text: "Значительные повреждения", value: "DAMAGE"}
    ]
}

export default damages