import { useSelector } from "react-redux";
import styles from "./VehicleDescription.module.css";
import languages from "../languages";

const VehicleDescription = () => {
  const activeLang = useSelector((state) => state.activeLanguage.lang)
  const vehicleData = useSelector(
    (state) => state.vehiclesData.data
  );

  return (
    <div className={styles["description-content"]}>
      <p className={styles["section-title"]}>{languages[activeLang].auctionPage.title}</p>
      <div className={styles["description-container"]}>
        <ul className={styles["description-block"]}>
          <li>
            <div className={styles["flex"]}>
              <p className={styles["name"]}>{languages[activeLang].auctionPage.docType}</p>
              <p className={styles["value"]}>
                {vehicleData && vehicleData.attributes.ownership_document_type}
              </p>
            </div>
          </li>
          <li>
            <div className={styles["flex"]}>
              <p className={styles["name"]}>{languages[activeLang].auctionPage.vin}</p>
              <p className={styles["value"]}>
                {vehicleData ? vehicleData.attributes.vin_code : " - "}
              </p>
            </div>
          </li>
          <li>
            <div className={styles["flex"]}>
              <p className={styles["name"]}>{languages[activeLang].auctionPage.odometer}</p>
              <p className={styles["value"]}>
                {vehicleData ? vehicleData.attributes.odometer_value_km : " - "}
              </p>
            </div>
          </li>
          <li>
            <div className={styles["flex"]}>
              <p className={styles["name"]}>{languages[activeLang].auctionPage.key}</p>
              <p className={styles["value"]}>{vehicleData && (
                vehicleData.attributes.key_fob === "PRESENT" ? " Yes " : " No "
              )}</p>
            </div>
          </li>
          <li>
            <div className={styles["flex"]}>
              <p className={styles["name"]}>{languages[activeLang].auctionPage.engine}</p>
              <p className={styles["value"]}>
                {vehicleData ? vehicleData.attributes.engine_volume_cm : " - "}
              </p>
            </div>
          </li>
          <li>
            <div className={styles["flex"]}>
              <p className={styles["name"]}>{languages[activeLang].auctionPage.fuelType}</p>
              <p className={styles["value"]}>
                {vehicleData ? vehicleData.attributes.fuel_type : " - "}
              </p>
            </div>
          </li>
          <li>
            <div className={styles["flex"]}>
              <p className={styles["name"]}>{languages[activeLang].auctionPage.gearbox}</p>
              <p className={styles["value"]}>
                {vehicleData ? vehicleData.attributes.transmission : " - "}
              </p>
            </div>
          </li>
        </ul>

        <ul className={styles["description-block"]}>
          <li>
            <div className={styles["flex"]}>
              <p className={styles["name"]}>{languages[activeLang].auctionPage.wheels}</p>
              <p className={styles["value"]}>
                {vehicleData && vehicleData.attributes.drive_type}
              </p>
            </div>
          </li>
          <li>
            <div className={styles["flex"]}>
              <p className={styles["name"]}>{languages[activeLang].auctionPage.mark}</p>
              <p className={styles["value"]}>
                {vehicleData ? vehicleData.attributes.maker : " - "}
              </p>
            </div>
          </li>
          <li>
            <div className={styles["flex"]}>
              <p className={styles["name"]}>{languages[activeLang].auctionPage.model}</p>
              <p className={styles["value"]}>
                {vehicleData ? vehicleData.attributes.odometer_value_km : " - "}
              </p>
            </div>
          </li>
          <li>
            <div className={styles["flex"]}>
              <p className={styles["name"]}>{languages[activeLang].auctionPage.year}</p>
              <p className={styles["value"]}>{vehicleData ? vehicleData.attributes.year : " - "}</p>
            </div>
          </li>
          <li>
            <div className={styles["flex"]}>
              <p className={styles["name"]}>{languages[activeLang].auctionPage.color}</p>
              <p className={styles["value"]}>
                {vehicleData ? vehicleData.attributes.color : " - "}
              </p>
            </div>
          </li>
          <li>
            <div className={styles["flex"]}>
              <p className={styles["name"]}>{languages[activeLang].auctionPage.owner}</p>
              <p className={styles["value"]}>
                {vehicleData && " - "}
              </p>
            </div>
          </li>
          <li>
            <div className={styles["flex"]}>
              <p className={styles["name"]}>{languages[activeLang].auctionPage.seller}</p>
              <p className={styles["value"]}>
                {vehicleData && " - "}
              </p>
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default VehicleDescription;
