import { useState } from 'react';
import { useDispatch } from 'react-redux'
import { formDataActions } from '../../store/formData';
import Gel from '../svg/Gel';
import styles from './Prices.module.css'
import languages from '../languages';
import { useSelector } from 'react-redux';

const Prices = (props) => {

    const activeLang = useSelector((state) => state.activeLanguage.lang);

    const [isUSD, setIsUSD] = useState(true)
    
    const dispatch = useDispatch();

    const priceOnKeyDownHandler = (e) => {
        if(e.keyCode === 8) return
        if(e.keyCode < 96 || e.keyCode > 106){
            e.preventDefault()
        }
    }

    const priceFromTypeHandler = (e) => {
        const priceFrom = e.target.value;
        dispatch(formDataActions.changePriceFrom(priceFrom))
        props.priceFrom(priceFrom)
    }

    const priceToTypeHandler = (e) => {
        const priceTo = e.target.value;
        dispatch(formDataActions.changePriceTo(priceTo))
        props.priceTo(priceTo)
    }
    
    const changeCarrencyHandler = () => {
        dispatch(formDataActions.changeCurrency())
        setIsUSD(!isUSD)
    }

    const styleObj = {
        marginTop: props.margin ? "30px" : "0px"
    }


    return (
        <div className={styles['input-block']} style={styleObj}>
            <input type="text" placeholder={`${languages[activeLang].search.price} - ${languages[activeLang].search.from}`} className={styles['price-from']} onKeyDown={priceOnKeyDownHandler} onKeyUp={priceFromTypeHandler} />
            <input type="text" placeholder={`${languages[activeLang].search.price} - ${languages[activeLang].search.to}`} className={styles['price-to']} onKeyDown={priceOnKeyDownHandler} onKeyUp={priceToTypeHandler}/>
            <button type='button' className={styles['currency']} onClick={changeCarrencyHandler}>{(isUSD) ? "$" : <Gel />}</button>
        </div>
    )
}

export default Prices