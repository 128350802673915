const Vehicle = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="48"
      viewBox="0 0 73 61"
      fill="none"
    >
      <path
        d="M72.7313 22.508L70.8103 19.1523C70.7239 19.0044 70.5826 18.8966 70.417 18.8526C70.2515 18.8085 70.0753 18.8318 69.9268 18.9172L65.8173 21.3165L59.4708 3.79243C59.1357 2.74678 58.477 1.83467 57.5897 1.1878C56.7024 0.540935 55.6325 0.192779 54.5345 0.193605H19.4135C16.6009 0.193605 14.1044 2.00923 13.2372 4.69214L7.19048 21.3246L3.0729 18.9253C2.92445 18.8399 2.74821 18.8166 2.58268 18.8607C2.41715 18.9047 2.27576 19.0125 2.1894 19.1604L0.268409 22.508C0.0900883 22.816 0.19546 23.205 0.503467 23.3834L5.39917 26.2365L4.22388 29.4787C4.12661 29.738 4.07798 30.0136 4.07798 30.2892V58.5125C4.07798 59.785 5.03442 60.8144 6.20972 60.8144H11.689C12.686 60.8144 13.5533 60.0606 13.764 59.0069L14.3881 55.9511H58.6116L59.2357 59.0069C59.4545 60.0606 60.3137 60.8144 61.3107 60.8144H66.79C67.9653 60.8144 68.9217 59.785 68.9217 58.5125V30.2892C68.9217 30.0136 68.8731 29.738 68.7758 29.4787L67.6005 26.2365L72.4881 23.3834C72.6353 23.2984 72.7432 23.1591 72.7887 22.9954C72.8341 22.8317 72.8135 22.6566 72.7313 22.508ZM16.3983 39.3349C14.607 39.3349 13.1561 37.884 13.1561 36.0927C13.1561 34.3014 14.607 32.8505 16.3983 32.8505C18.1896 32.8505 19.6405 34.3014 19.6405 36.0927C19.6405 37.884 18.1896 39.3349 16.3983 39.3349ZM47.8475 45.414C47.8475 45.7707 47.5557 46.0625 47.1991 46.0625H25.8006C25.444 46.0625 25.1522 45.7707 25.1522 45.414V38.6054C25.1522 38.2488 25.444 37.957 25.8006 37.957H29.0428C29.3995 37.957 29.6913 38.2488 29.6913 38.6054V41.5234H43.3084V38.6054C43.3084 38.2488 43.6002 37.957 43.9569 37.957H47.1991C47.5557 37.957 47.8475 38.2488 47.8475 38.6054V45.414ZM56.6014 39.3349C54.8101 39.3349 53.3592 37.884 53.3592 36.0927C53.3592 34.3014 54.8101 32.8505 56.6014 32.8505C58.3927 32.8505 59.8436 34.3014 59.8436 36.0927C59.8436 37.884 58.3927 39.3349 56.6014 39.3349ZM12.8319 22.8808L18.7246 6.67798L18.7651 6.57261L18.7975 6.46724C18.8867 6.19976 19.1298 6.02144 19.4135 6.02144H54.0725L60.184 22.8808H12.8319Z"
        fill="black"
      />
    </svg>
  );
};

export default Vehicle