const Mail = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="26"
      height="20"
      viewBox="0 0 26 20"
      fill="none"
    >
      <path
        d="M24.8145 0.00871444C24.7038 -0.00290481 24.5922 -0.00290481 24.4814 0.00871444H1.73141C1.5856 0.011002 1.44076 0.0332622 1.30078 0.0748959L13.0414 11.9793L24.8145 0.00871444Z"
        fill="#2D2D2D"
      />
      <path
        d="M25.9025 1.29547L14.0725 13.2909C13.768 13.599 13.3562 13.772 12.9269 13.772C12.4976 13.772 12.0857 13.599 11.7812 13.2909L0.056875 1.38647C0.020832 1.52135 0.00172143 1.66033 0 1.80011V18.3455C0 18.7843 0.171205 19.2051 0.475952 19.5154C0.780698 19.8257 1.19402 20 1.625 20H24.375C24.806 20 25.2193 19.8257 25.524 19.5154C25.8288 19.2051 26 18.7843 26 18.3455V1.80011C25.9935 1.62775 25.9606 1.45748 25.9025 1.29547V1.29547ZM2.73813 18.3455H1.60875V17.1625L7.51562 11.1979L8.66125 12.3643L2.73813 18.3455ZM24.3587 18.3455H23.2212L17.2981 12.3643L18.4437 11.1979L24.3506 17.1625L24.3587 18.3455Z"
        fill="#2D2D2D"
      />
    </svg>
  );
};

export default Mail