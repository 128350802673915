const UserIcon = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.iconWidth}
      height={props.iconHeight}
      viewBox="0 0 36 36"
      fill="none"
    >
      <path
        d="M3 36C3 36 0 36 0 33C0 30 3 21 18 21C33 21 36 30 36 33C36 36 33 36 33 36H3ZM18 18C20.3869 18 22.6761 17.0518 24.364 15.364C26.0518 13.6761 27 11.3869 27 9C27 6.61305 26.0518 4.32387 24.364 2.63604C22.6761 0.948212 20.3869 0 18 0C15.6131 0 13.3239 0.948212 11.636 2.63604C9.94821 4.32387 9 6.61305 9 9C9 11.3869 9.94821 13.6761 11.636 15.364C13.3239 17.0518 15.6131 18 18 18Z"
        fill="black"
      />
    </svg>
  );
};

export default UserIcon;
