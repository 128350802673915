import Facebook from "../svg/Facebook";
import Youtube from "../svg/Youtube";
import Instagram from "../svg/Instagram";
import styles from "./Socials.module.css";

const Social = () => {
  return (
    <div className={styles["social-block-container"]}>
      <div className={styles["social"]}>
        <Facebook iconWidth="30" contact={true} backgroundColor="#1877F2" />
        <p className={styles['social-name']}>Facebook</p>
        <p className={styles['social-user']}>Car Express</p>
      </div>
      <div className={styles["social"]} >
        <Instagram iconWidth="30" contact={true} backgroundColor="#FF7135" />
        <p className={styles['social-name']}>Instagram</p>
        <p className={styles['social-user']}>@Carexpress</p>
      </div>
      <div className={styles["social"]}>
        <Youtube iconWidth="30" contact={true} backgroundColor="#FD3737" />
        <p className={styles['social-name']}>Youtube</p>
        <p className={styles['social-user']}>Car Express</p>
      </div>
    </div>
  );
};

export default Social;
