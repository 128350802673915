import axios from "axios";
import { Fragment, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { vehicleDataActions } from "../../store/vehicle";
import Vehicle from "../MainPage/Vehicle";
import Loader from "../UI/Loader";
import styles from "./VehiclesList.module.css";
import { useLocation } from "react-router-dom";

const VehiclesList = () => {
  const location = useLocation();
  const [loadMore, setLoadMore] = useState(false);
  const lastSegmentOfUrl = window.location.href.substring(
    window.location.href.lastIndexOf("/") + 1
  );
  const dispatch = useDispatch();
  const vehicleData = useSelector((state) => state.vehiclesData);

  useEffect(() => {
    let search = window.location.search.substring(1);
    let searchings;
    if(search !== ""){
      searchings = JSON.parse(
        '{"' +
          decodeURI(search)
            .replace(/"/g, '\\"')
            .replace(/&/g, '","')
            .replace(/=/g, '":"') +
          '"}'
      );
    }
  
    searchings = {...searchings, loadmore: false}

    if (search === "") {
      axios(`/vehicles/auction/${lastSegmentOfUrl}?loadmore=false`).then(
        (response) => {
          const data = response.data.vehicles;
          const next = response.data.next;
          data.forEach((el) => {
            el.image = el.image.replaceAll("\\", "/");
          });
          dispatch(vehicleDataActions.firstRequest(data));
          dispatch(vehicleDataActions.changeNextPage(next));
        }
      );
    } else {
      axios.get(`/vehicles/filter`, {params: searchings}).then(
        (response) => {
          const data = response.data.vehicles;
          const next = response.data.next;
          data.forEach((el) => {
            el.image = el.image.replaceAll("\\", "/");
          });
          dispatch(vehicleDataActions.firstRequest(data));
          dispatch(vehicleDataActions.changeNextPage(next));
        }
      );
    }

  }, [dispatch, lastSegmentOfUrl, location]);

  const loadMoreButtonHandler = () => {
    setLoadMore(true);
    axios(
      `/vehicles/auction/${lastSegmentOfUrl}?loadmore=true&next=${encodeURIComponent(
        vehicleData.next
      )}`
    ).then((response) => {
      const data = response.data.vehicles;
      const next = response.data.next;
      data.forEach((el) => {
        el.image = el.image.replaceAll("\\", "/");
      });
      dispatch(vehicleDataActions.auctionPageHandler(data));
      dispatch(vehicleDataActions.changeNextPage(next));
      setLoadMore(false);
    });
  };

  return (
    <Fragment>
      <div className={styles["vehicles-list"]}>
        {vehicleData.auctionPage.length > 0 ? (
          vehicleData.auctionPage.map((vehicle) => (
            <Vehicle key={vehicle.lotNumber} vehicleData={vehicle} />
          ))
        ) : (
          <Loader />
        )}
      </div>
      {loadMore && <Loader />}
      <div className={styles["load-more"]}>
        <button
          className={styles["load-more-button"]}
          onClick={loadMoreButtonHandler}
        >
          მეტის ჩატვირთვა
        </button>
      </div>
    </Fragment>
  );
};

export default VehiclesList;
