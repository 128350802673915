import styles from "./ContactUs.module.css";
import ContactUsItem from "./ContactUsItem";
import Phone from "../svg/Phone";
import Location from "../svg/Location";
import Mail from "../svg/Mail";
import languages from "../languages";
import {useSelector} from "react-redux"

const ContactUs = () => {

  const activeLang = useSelector((state) => state.activeLanguage.lang);

  return (
    <div className={styles["contact-us-container"]}>
      <h3 className={styles["section-title"]}>{languages[activeLang].contacts.contactUs}</h3>
      <div className={styles["contact-us-content"]}>
        <div className={styles["contact-item-content"]}>
          <h3 className={styles["location"]}>{languages[activeLang].contacts.info.tbilisi.name}</h3>
          <div className={styles["item-list"]}>
            <ContactUsItem
              icon={<Phone />}
              title={languages[activeLang].contacts.info.tbilisi.phone.title}
              htmlHref="tel: 032-2-424-100"
              itemText="032 2 424 100"
            />
            <ContactUsItem
              icon={<Location />}
              title={languages[activeLang].contacts.info.tbilisi.location.title}
              htmlHref="https://goo.gl/maps/t4VHw4b6prTFhTZV7"
              itemText={languages[activeLang].contacts.info.tbilisi.location.value}
            />
            <ContactUsItem
              icon={<Mail />}
              title={languages[activeLang].contacts.info.tbilisi.email.title}
              htmlHref="mailto: info@carexpress.com"
              itemText="info@carexpress.com"
            />
          </div>
        </div>
        <div className={styles["hr"]} />
        <div className={styles["contact-item-content"]}>
          <h3 className={styles["location-usa"]}>USA</h3>
          <div className={styles["item-list"]}>
            <ContactUsItem
              icon={<Phone />}
              title="Phone"
              htmlHref="tel: (123) 456 -7890"
              itemText="(123) 456 -7890"
            />
            <ContactUsItem
              icon={<Location />}
              title="Location"
              htmlHref="https://goo.gl/maps/t4VHw4b6prTFhTZV7"
              itemText="95 Place de la Gare"
            />
            <ContactUsItem
              icon={<Mail />}
              title="Email"
              htmlHref="mailto: info@carexpress.com"
              itemText="info@carexpress.com"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
