import Offer from "./Offer";
import styles from "./AditionalServices.module.css";
import EvacuatorIcon from "../svg/EvacuatorIcon";
import RegAsDealer from "../svg/RegAsDealer";
import SafetyTransportation from "../svg/SafetyTransportation";
import languages from "../languages";
import {useSelector} from "react-redux"

const AditionalServices = () => {

  const activeLang = useSelector((state) => state.activeLanguage.lang);

  return (
    <section className={styles["additional-services"]}>
      <h2 className={styles['services-title']}>{languages[activeLang].additionalServices.title}</h2>
      <div className={styles['additional-services-container']}>
        <Offer
          offerTitle={languages[activeLang].additionalServices.evacuator.name}
          offerText={languages[activeLang].additionalServices.evacuator.text}
          bColor="#E9EFF5"
          icon={<EvacuatorIcon iconWidth="50" color="black" />}
        />
        <Offer
          offerTitle={languages[activeLang].additionalServices.dealer.name}
          offerText={languages[activeLang].additionalServices.dealer.text}
          bColor="#EBF3ED"
          icon={<RegAsDealer iconWidth="50" color="black" />}
        />
        <Offer
          offerTitle={languages[activeLang].additionalServices.transportation.name}
          offerText={languages[activeLang].additionalServices.transportation.text}
          bColor="#FAF1E8"
          icon={<SafetyTransportation iconWidth="50" color="black" />}
        />
      </div>
    </section>
  );
};

export default AditionalServices;
