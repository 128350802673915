import axios from "axios";
import { useEffect, useState } from "react";
import styles from "./Admin.module.css"
import SimilarVehicles from "../Vehicle/SimilarVehicles";
import SimpleDataTable from "../../Common/DataTable";

const AdminDealers = () => {
    const [dealers, setDealers] = useState([]);

    useEffect(() => {
      axios.get("/dealers/form").then((response) => {
        setDealers(response.data.data);
      });
    }, []);

    const actions = {
      main: {

      },
      individual: {

      }
    }
  
    return (
      <SimpleDataTable items={dealers} searchable={["name", "city", "phone"]} actions={actions} />
    );
}

export default AdminDealers