import { Route, Switch } from "react-router-dom";
import "./App.css";
import { useState, useEffect } from "react";
import AuctionVehicle from "./pages/AuctionVehicle";
// import AutoSalon from "./pages/AutoSalon";
import AutoSalonVehicle from "./pages/AutosalonVehicle";
import Contact from "./pages/Contact";
import CopartAuction from "./pages/CopartAuction";
import Dilers from "./pages/Dilers";
import MainPage from "./pages/MainPage";
import Header from "./components/Common/Header";
import Footer from "./components/Common/Footer";
import AdminAuth from "./pages/AdminAuth";
import AdminPage from "./pages/AdminPage";
import AutoMarket from "./pages/AutoMarket";
import { useLocation } from "react-router-dom";

function App() {

  const [isAdmin, setIsAdmin] = useState(false);
  const location = useLocation();

  useEffect(() => {
    if(location.pathname === "/admin"){
      setIsAdmin(true);
    }else{
      setIsAdmin(false);
    }
  }, [location])

  return (
    <div>
      <Header />
      <main style={{paddingTop: '73px'}} className="main-container">
        <Switch>
          <Route path="/" exact>
            <MainPage />
          </Route>
          <Route path="/catalogue/:pageNumber">
            <CopartAuction />
          </Route>
          <Route path="/auto-market/:pageNumber">
            <AutoSalonVehicle />
          </Route>
          <Route path="/auction/:vehicleId">
            <AuctionVehicle />
          </Route>
          <Route path="/dealers">
            <Dilers />
          </Route>
          <Route path="/contact-us">
            <Contact />
          </Route>
          <Route path="/auto-market" exact>
            <AutoMarket />
          </Route>
          <Route path="/admin/auth" exact>
            <AdminAuth />
          </Route>
          <Route path="/admin" exact >
            <AdminPage />
          </Route>
        </Switch>
      </main>
      {!isAdmin && <Footer />}
    </div>
  );
}

export default App;
