const languages = {
  EN: {
    header: {
      nav: {
        main: "Main",
        auction: "Auction",
        autoMarket: "Automarket",
        dealer: "Register As Dealer",
        contact: "Contact",
      },
      search: "Enter lot or VIN code",
    },
    banner: {
      title: "Bring a car from America",
      subtext: "Choose the desired car, 30% cheaper then local market.",
      button: "Consultation",
    },
    search: {
      mark: "Mark",
      model: "Model",
      year: "Year",
      price: "Price",
      from: "From",
      to: "To",
      condition: "Condition",
      sellType: "Sell Type",
      search: "Search",
      choose: "Choose",
      buyNow: "Buy now",
      conditions: ["Normal wear", "Minor damage", "Damaged"],
    },
    offers: {
      ourOffers: "What do we you offer?",
      consultation: {
        title: "Consultation",
        text: "We choose your car and look for the best options at American auctions",
      },
      buy: {
        title: "Buy",
        text: "We buy your car at America's largest auctions, 30% cheaper than the market",
      },
      funding: {
        title: "Funding",
        text: "If you wish, we finance your vehicle and transportation costs",
      },
      transportation: {
        title: "Transportation",
        text: "We take care of fast and safe transportation of your vehicle",
      },
    },
    mainCatalog: {
      title: "Choose the desired car",
      button: "Catalog",
    },
    additionalServices: {
      title: "Additional services",
      evacuator: {
        name: "Tow truck services",
        text: "To make your car buying experience even more comfortable, we bring your car to your doorstep",
      },
      dealer: {
        name: "Registration as a dealer",
        text: "Sign up as a dealer, get access to live auctions, play with cars yourself and get special shipping prices",
      },
      transportation: {
        name: "Safe transportation",
        text: "If desired, transportation with us is fully insured, be calm and wait for the fast and safe transportation of your vehicle",
      },
    },
    contactForm: {
      title: ["Cars from America's", "Largest Auctions"],
      text: [
        "Fill out the form and our team will",
        "contact you as soon as possible",
      ],
      name: "Name",
      number: "Phone",
      agreement: ["I agree", "that"],
      send: "Send",
      Sent: "Request sent successfully",
    },
    footer: {
      navigation: {
        title: "Navigation",
        frequency: "Frequently Asked Questions",
        dealer: "Registration as dealer",
        searchContainer: "Search container",
        cabinet: "My cabinet",
      },
      services: {
        title: "Our services",
        consultation: "Consultation",
        transportation: "Transportation",
        funding: "Funding",
      },
      contact: {
        title: "Contact",
        address: "Green Budapest (G.Phanjikidze st. N22)",
        email: "info@carexpress.ge",
        phone: "032 2 424 100",
      },
      subscription: {
        subscribe: "Subscribe news",
        email: "Email",
        button: "Subscribe",
      },
    },
    dealers: {
      title: "Registration as dealer",
      banner:
        "Become a Carexpress partner, register as a dealer and enjoy the conditions tailored to you",
      name: "Name",
      city: "City",
      number: "Phone",
      carPerMonth: "car per month",
      sent: "Request sent successfully",
      send: "Send",
      offers: {
        title: ["Bring a car from America's ", "largest auctions"],
        text: [
          "Become our dealer, CarExpress allows you to buy cars from ",
          "America's largest auctions without a middleman.",
        ],
        offer: {
          codes: {
            title: "Get auction codes",
            text: "Take part in America's largest auctions yourself, buy cars and turn interest into a source of income with CarExpress.",
          },
          transportation: {
            title: "Take advantage of special shipping prices",
            text: "Get transportation prices tailored to you, we have a special offer on transportation for our partner dealers.",
          },
          funding: {
            title: "Get funding approved",
            text: "Take advantage of financing conditions tailored to you, if you wish, finance the cost of the car, transportation, landscaping and repairs.",
          },
        },
      },
      qustions: {
        title: "Frequently Asked Questions",
        question: [
          {
            question: "Who are we?",
            answer:
              'With 10 years of experience, the "Carexpress" company is one of the largest car importers in the Caucasus. The company imports automobiles and any type of special equipment, as well as auto parts and accessories from America.',
          },
          {
            question: "Why should I use your service?",
            answer: `"Carexpress" company is a licensed car importer, which allows to participate in the world's leading car auctions. The company, from the day of deterioration, on the way of its development, was staffed with a highly qualified team and today has about 30 branches throughout Georgia and about 10 representative offices outside Georgia. The number of cars imported by the company exceeds 100,000.`,
          },
          {
            question: "How long does it take to transport?",
            answer:
              "The average shipping time is 1.5-2.5 months and depends on the location of the vehicle in America.",
          },
          {
            question: "I want to buy more than 1 car, how do I do it?",
            answer:
              "The company's services can be used by those who want to buy a car, as well as people interested in auto import business. By cooperating with us, you can receive offers tailored to you, both in terms of transportation and financing. To register as a dealer, visit our website or call us at 2 424 100",
          },
        ],
      },
    },
    contacts: {
      title: "Contact",
      contactUs: "Contact Us",
      messageUs: "Message Us",
      info: {
        tbilisi: {
          name: "Tbilisi",
          phone: {
            title: "Phone",
            value: "032 2 424 100",
          },
          location: {
            title: "Location",
            value: "G.Phanjikidze st N22",
          },
          email: {
            title: "Email",
            value: "info@carexpress.com",
          },
        },
        usa: {
          name: "USA",
          phone: {
            title: "Phone",
            value: "(123) 456 -7890",
          },
          location: {
            title: "Location",
            value: "95 Place de la Gare",
          },
          email: {
            title: "Email",
            value: "info@carexpress.com",
          },
        },
      },
      name: "Name",
      email: "Email",
      subject: "Subject",
      message: "Message",
      sent: "Message sent successfully",
      send: "Send",
    },
    auction: {
      loadMore: "Load More",
      search: "Search",
    },
    auctionPage: {
      location: "Location",
      buy: "Buy car",
      manager: "George",
      funding: "Funding",
      title: "Car description",
      fundingText:
        "Take advantage of the best leasing conditions, finance the costs of the vehicle, transportation and repairs",
      contactUs: "Contact us",
      monday: "Mon",
      friday: "Fri",
      Saturday: "Sat",
      docType: "Doc type",
      vin: "VIN code",
      odometer: "Odometer",
      key: "Key",
      engine: "Engine",
      fuelType: "Fuel type",
      gearbox: "Gearbox",
      wheels: "Driving wheels",
      mark: "Mark",
      model: "Model",
      year: "Year",
      color: "Color",
      owner: "Owner",
      seller: "Seller",
      application: "Filling out the application",
      usa: "USA",
      name: "Name",
      phone: "Phone",
      buyType: "Buy type"
    },
  },
  GE: {
    header: {
      nav: {
        main: "მთავარი",
        auction: "აუქციონი",
        autoMarket: "ავტომარკეტი",
        dealer: "დილერად რეგისტრაცია",
        contact: "კონტაქტი",
      },
      search: "ჩაწერეთ lot ან VIN კოდი",
    },
    banner: {
      title: "ჩამოიყვანე ავტომობილი ამერიკიდან",
      subtext: "შეარჩიე სასურველი ავტომობილი, საბაზროზე 30%-ით იაფად.",
      button: "კონსულტაცია",
    },
    search: {
      mark: "მწარმოებელი",
      model: "მოდელი",
      year: "წელი",
      price: "ფასი",
      from: "დან",
      to: "მდე",
      condition: "მდგომარეობა",
      sellType: "გაყიდვის ტიპი",
      search: "ძიება",
      choose: "არჩევა",
      buyNow: "იყიდე ახლავე",
      conditions: ["დაუზიანებელი", "მცირე დაზიანებით", "დაზიანებული"],
    },
    offers: {
      ourOffers: "რას გთავაზობთ?",
      consultation: {
        title: "კონსულტაცია",
        text: "ვარჩევთ შენს ავტომობილს და ვეძებთ საუკეთესო ვარიანტებს ამერიკის აუქციონებზე",
      },
      buy: {
        title: "შეძენა",
        text: "ვყიდულობთ შენს ავტომობილს ამერიკის უმსხვილეს აუქციონებზე, საბაზროზე 30%-ით იაფად",
      },
      funding: {
        title: "დაფინანსება",
        text: "სურვილის შემთხვევაში ვაფინანსებთ თქვენი ავტომობილისა და ტრანსპორტირების ხარჯებს",
      },
      transportation: {
        title: "ტრანსპორტირება",
        text: "ვზრუნავთ თქვენი ავტომობილის სწრაფ და უსაფრთხო ტრანსპორტირებაზე",
      },
    },
    mainCatalog: {
      title: "აირჩიეთ სასურველი ავტომობილი",
      button: "კატალოგი",
    },
    additionalServices: {
      title: "დამატებითი სერვისები",
      evacuator: {
        name: "ევაკუატორის მომსახურება",
        text: "ავტომობილის შეძენის გამოცდილება კიდევ უფრო კომფორტული რომ გაგიხადოთ ჩვენ მოგიყვანთ თქვენს ავტომობილს თქვენი სახლის კარებთან",
      },
      dealer: {
        name: "დილერად რეგისტრაცია",
        text: "დარეგისტრირდი დილერად, მიიღე ლაივ აუქციონებზე წვდომა, თავად ითამაშე ავტომობილებზე და მიიღე სპეციალური ტრანსპორტირების ფასები",
      },
      transportation: {
        name: "უსაფრთხო ტრანსპორტირება",
        text: "სურვილის შემთხვევაში ჩვენთან ტრანსპორტირება სრულიად დაზღვეულია, იყავი მშვიდად და დაელოდე შენი ავტომობილის სწრაფ და უსაფრთხო ტრანსპორტირებას",
      },
    },
    contactForm: {
      title: ["ავტომობილები ამერიკის", "უდიდესი აუქციონებიდან"],
      text: ["შეავსე ფორმა და ჩვენი გუნდი უმოკლეს", "ვადაში დაგიკავშირდება"],
      name: "სახელი",
      number: "ნომერი",
      agreement: ["წინამდებარე განცხადით ვადასტურებ", "რომ"],
      send: "გაგზავნა",
      Sent: "მოთხოვნა წარმატებით გაიგზავნა",
    },
    footer: {
      navigation: {
        title: "ნავიგაცია",
        frequency: "ხშირად დასმული კითხვები",
        dealer: "დილერად რეგისტრაცია",
        searchContainer: "კონტეინერის ძებნა",
        cabinet: "პირადი კაბინეტი",
      },
      services: {
        title: "ჩვენი სერვისები",
        consultation: "კონსულტაცია",
        transportation: "ტრანსპორტირება",
        funding: "დაფინანსება",
      },
      contact: {
        title: "კონტაქტი",
        address: "Green Budapest (გ.ფანჯიკიძის N22)",
        email: "info@carexpress.ge",
        phone: "032 2 424 100",
      },
      subscription: {
        subscribe: "გამოიწერეთ სიახლეები",
        email: "ელ-ფოსტა",
        button: "გამოწერა",
      },
    },
    dealers: {
      title: "დილერად რეგისტრაცია",
      banner:
        "გახდი ქარექსპრესის პარტნიორი, დარეგისტრირდი დილერად და ისარგებლე შენზე მორგებული პირობებით",
      name: "სახელი",
      city: "ქალაქი",
      number: "ნომერი",
      carPerMonth: "ავტომობილი თვეში",
      sent: "მოთხოვნა წარმატებით გაიგზავნა",
      send: "გაგზავნა",
      offers: {
        title: ["ჩამოიყვანე ავტომობილი ამერიკის", "უმსხვილესი აუქციონებიდან"],
        text: [
          "გახდი ჩვენი დილერი, ქარექსპრესი გაძლევს საშუალებას შუამავლის გარეშე",
          "შეიძინო ავტომობილები ამერიკის უმსხვილესი აუქციონებიდან",
        ],
        offer: {
          codes: {
            title: "მიიღე აუქციონის კოდები",
            text: "თავად მიიღე მონაწილეობა ამერიკის უმსხვილეს აუქციონებზე, შეიძინე ავტომობილები და აქციე ინტერესი შემოსავლის წყაროდ ქარექსპრესთან ერთად.",
          },
          transportation: {
            title: "ისარგებლე სპეციალური ტრანსპორტირების ფასები",
            text: "მიიღე ტრანსპორტირების შენზე მორგებული ფასები,ჩვენ ჩვენი პარტნიორი დილერებისთვის გვაქვს განსაკუთრებული შეთავაზება ტრანსპორტირებაზე.",
          },
          funding: {
            title: "დაიმტკიცე დაფინანსება",
            text: "ისარგებლე შენზე მორგებული დაფინანსების პირობებით, სურვილის შემთხვევაში დაიფინანსე ავტომობილის ღირებულების, ტრანსპორტირების, განბაღებისა და შეკეთების თანხა.",
          },
        },
      },
      qustions: {
        title: "ხშირად დასმული კითხვები",
        question: [
          {
            question: "ვინ ვართ ჩვენ",
            answer:
              "10 წლიანი გამოცდილებით კომპანია “ქარექსპრესი” ერთ-ერთი ყველაზე დიდი ავტოიმპორტიორია კავკასიაში. კომპანია ახორციელებს ავტომობილებისა და ნებისმიერი ტიპის სპეც-ტექნიკის, ასევე ავტონაწილებისა და აქსესუარების იმპორტს ამერიკიდან.",
          },
          {
            question: "რატომ უნდა ვისარგებლო თქვენი მომსახურებით?",
            answer:
              "კომპანია “ქარექსპრესი” ლიცენზირებული ავტო იმპორტიორია, რაც საშუალებას აძლევს მონაწილეობა მიიღოს მსოფლიოს წამყვან საავტომობილო აუქციონებზე. კომპანია, დაარესების დღიდან, მისი განვითარების გზაზე, დაკომპლექტდა მაღალკვალიფიციური გუნდით და დღესდღეისობით საქართველოს მასშტაბით 30-მდე ფილიალს და საქართველოს ფარგლებს გარეთ 10-მდე წარმომადგენლობას ითვლის. კომპანიის მიერ იმპორტირებული მანქანების რაოდენობა 100 000 ს აჭარბებს.",
          },
          {
            question: "რა დრო ჭირდება ტრანსპორტირებას?",
            answer:
              "ტრანსპორტირების ვადა საშუალოდ მოიცავს 1.5-2.5 თვეს და დამოკიდებულია ავტომობილის მდებარეობაზე ამერიკაში.",
          },
          {
            question: "მსურს 1-ზე მეტი ავტომობილის შეძენა, როგორ მოვიქცე?",
            answer:
              "კომპანიის სერვისებით სარგებლობა შეუძლიათ, როგორც ავტომობილის შეძენის მსურველებს, ასევე ავტო იმპორტის ბიზნესით დაინტერესებულ ადამიანებს. ჩვენთან თანამშრომლობით თქვენ შეგიძლიათ მიიღოთ თქვენზე მორგებული შეთავაზებები, როგორც ტრანსპორტირების ასევე დაფინანსების კუთხით. დილერად დასარეგისტრირებლად ეწვიეთ ჩვენს გვერდს ან დაგვიკავშირდით 2 424 100",
          },
        ],
      },
    },
    contacts: {
      title: "კონტაქტი",
      contactUs: "დაგვიკავშირდით",
      messageUs: "მოგვწერეთ",
      info: {
        tbilisi: {
          name: "თბილისი",
          phone: {
            title: "ტელეფონი",
            value: "032 2 424 100",
          },
          location: {
            title: "ლოკაცია",
            value: "გ.ფანჯიკიძის N22",
          },
          email: {
            title: "მეილი",
            value: "info@carexpress.com",
          },
        },
        usa: {
          name: "USA",
          phone: {
            title: "Phone",
            value: "(123) 456 -7890",
          },
          location: {
            title: "Location",
            value: "95 Place de la Gare",
          },
          email: {
            title: "Email",
            value: "info@carexpress.com",
          },
        },
      },
      name: "სახელი",
      email: "ელ-ფოსტა",
      subject: "თემა",
      message: "შეტყობინება",
      sent: "შეტყობინება წარმატებით გაიგზავნა",
      send: "გაგზავნა",
    },
    auction: {
      loadMore: "მეტის ჩატვირთვა",
      search: "ძებნა",
    },
    auctionPage: {
        location: "მდებარეობა",
        buy: "ავტომობილის ყიდვა",
        manager: "გიორგი",
        funding: "დაფინანსება",
        fundingText:
          "ისარგებლე ლიზინგის საუკეთესო პირობით, დაიფინანსე ავტომობილის, ტრანსპორტირებისა და შეკეთების ხარჯები",
        contactUs: "დაგვიკავშირდით",
        monday: "ორშ",
        friday: "პარ",
        Saturday: "შაბ",
        docType: "დოკუმენტის ტიპი",
        vin: "VIN კოდი",
        odometer: "გარბენი",
        key: "გასაღები",
        engine: "ძრავის მოცულობა",
        fuelType: "საწვავის ტიპი",
        gearbox: "გადაცემათა კოლოფი",
        wheels: "წამყვანი თვლები",
        mark: "მწარმოებელი",
        model: "მოდელი",
        year: "წელი",
        color: "ფერი",
        owner: "მფლობელი",
        seller: "გამყიდველი",
        application: "განაცხადის შევსება",
        usa: "ამერიკა",
        name: "სახელი",
        phone: "ტელეფონი",
        buyType: "შესყიდვის ტიპი"
      },
  },
  RU: {},
};

export default languages;
