import { useState } from 'react';
import styles from './AdminAuth.module.css';

const AdminAuth = () => {

    const [form, setForm] = useState({
        userName: "",
        password: ""
    })

    const usernameOnChangeHandler = (e) => {
        setForm((prevState) => {
            return {...prevState, userName: e.target.value}
        })
    }

    const passwordOnChangeHandler = (e) => {
        setForm((prevState) => {
            return {...prevState, password: e.target.value}
        })
    }

    const onSubmitFormHandler = () => {
        if(form.userName === "admin" && form.password === "CarExp2022!"){
            alert("auth")
        }else{
            alert("Username or password is not correct")
        }
    }

    return(
        <div className={styles['admin-auth-container']}>
            <div className={styles['auth-box']}>
                <p className={styles['title']}>ავტორიზაცია</p>
                <input type="text" onChange={usernameOnChangeHandler} placeholder='მომხმარებლის სახელი' className={styles['input-form']} />
                <input type="password" placeholder='პაროლი' onChange={passwordOnChangeHandler} className={styles['input-form']} />
                <button className={styles['submit-btn']} onClick={onSubmitFormHandler}>შესვლა</button>
            </div>
        </div>
    )
}

export default AdminAuth