import React from "react";
import ReactDOM from "react-dom";

import styles from "./TermsPopup.module.css";

const Backdrop = (props) => {
  return (
    <div className={styles["popup-modal"]}>
      <div className={styles["popup-background"]} onClick={props.onCloseHandler}></div>
      <div className={styles["popup-box-content"]}>
      <h3 className={styles["title-on-modal"]}>განაცხადის შევსება</h3>
    </div>
    </div>
  );
};

const TermsPopup = ({ closePopup }) => {
  const closePopupHandler = () => {
    closePopup(false);
  };

  return (
    <div className={styles["popup-modal"]}>
      {ReactDOM.createPortal(
        <Backdrop onCloseHandler={closePopupHandler} />,
        document.getElementById("popup-container")
      )}
    </div>
  );
};

export default TermsPopup;
