import { Fragment, useState } from "react";
import { useDispatch } from 'react-redux';
import DownIcon from "../svg/DownIcon";
import UpIcon from "../svg/UpIcon";
import DropdownWithTwoOption from "./DropdownWithTwoOption";
import { formDataActions } from "../../store/formData";
import { useDetectClickOutside } from "react-detect-click-outside";
import styles from "./Select.module.css";

const Select = (props) => {

  const [isShowen, setIsShowen] = useState(false);

  const dispatch = useDispatch();

  const selectOnClickHandler = (e) => {
    e.preventDefault();
    setIsShowen((prevState) => !prevState);
  };

  const closeDropDown = () => {
    setIsShowen(false)
  }

  const ref = useDetectClickOutside({onTriggered: closeDropDown})

  const optionOnClickHandler = (e) => {
    const selectedItem = e.target.outerText;
    const values = e.target.attributes['data-value'].value;
    props.selectState((prevState) => {
      return {...prevState,  [props.selectName]: selectedItem}
    })
    setIsShowen(!isShowen)
    dispatch(formDataActions.changeFormData({name: props.selectName, value: values}))
  }

  return (
    <div className={styles["select-container"]} ref={ref}>
      <div className={styles["select-item"]} onClick={selectOnClickHandler}>
        <p className={styles["selected-option"]}>{(props.selectedOption) ? props.selectedOption : 'name'}</p>
        {
          (isShowen) ? (
            <UpIcon iconWidth="15" color="#797979" />
          ) : (
            <DownIcon iconWidth="15" color="#797979" />
          )
        }
      </div>
      {isShowen && (
        <Fragment>
          {props.withTwoOption ? (
            <DropdownWithTwoOption dropDownData={props.selectData} selectOption={props.selectState} closeSelect={setIsShowen} />
          ) : (
            <div className={styles["select-dropdown"]}>
              <ul className={styles["dropdown-list"]}>
                {props.selectData.map((car, k) => {
                  return <li key={k * 5} onClick={optionOnClickHandler} onMouseDown={props.mouseDownHandler} data-value={car.value}>{car.text.replace("_", " ")}</li>;
                })}
              </ul>
            </div>
          )}
        </Fragment>
      )}
    </div>
  );
};

export default Select;
