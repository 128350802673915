import { React, useState } from "react";
import { NavLink, Link } from "react-router-dom";
import Bar from "../svg/Bar";
import DownIcon from "../svg/DownIcon";
import SearchIcon from "../svg/SearchIcon";
import UpIcon from "../svg/UpIcon";
import OutsideClickHandler from 'react-outside-click-handler';
import languages from "../languages";
import { useSelector, useDispatch } from "react-redux";
import { activeLanguageActions } from "../../store/activeLanguage";

import styles from "./Header.module.css";

const Header = () => {

  const [selectedLang, setSelectedLang] = useState("GE")

  const dispatch = useDispatch();

  const [showLanguages, setShowLanguages] = useState(false);
  const [searchInputIsValid, setSearchInputIsValid] = useState(false);
  const [showSearchInput, setShowSearchInput] = useState(false);
  const [showNavBar, setShowNavBar] = useState(false);
  const activeLanguage = useSelector((state) => state.activeLanguage.lang)
  let [searchInputValue, setSearchInputValue] = useState("");

  const showLanguagesHandler = () => {
    setShowLanguages(!showLanguages);
  };

  const changeLanguageHandler = (e) => {
    dispatch(activeLanguageActions.changeLanguage(e.target.outerText));
    setSelectedLang(e.target.outerText);
    setShowLanguages(!showLanguages);
  };

  const searchOnChangeHandler = (e) => {
    if (e.target.value.trim() !== "") {
      setSearchInputIsValid(true);
      setSearchInputValue(e.target.value)
    } else {
      setSearchInputIsValid(false);
    }
  };

  const showInputFieldHandler = () => {
    setShowSearchInput(!showSearchInput)
  }

  const barOnClickHandler = () => {
    setShowNavBar(!showNavBar)
  }

  return (
    <div className={styles["header-content"]}>
      <div className={styles["header-container"]}>
        <Link to="/" className={styles.logo  }>
          <span className={styles["color-red"]}>C</span>ar{" "}
          <span className={styles["color-red"]}>E</span>xpress
        </Link>
        
        {showNavBar && (
          <nav className={showNavBar? styles.navbar : styles.noheight}>
            <OutsideClickHandler onOutsideClick={() => setShowNavBar(false)}>
          <ul className={styles["navigation-list"]}>
            <li>
              <NavLink activeClassName={styles.active} to="/" exact onClick={() => setShowNavBar(!showNavBar)}>
                {languages[activeLanguage].header.nav.main}
              </NavLink>
            </li>
            <li>
              <NavLink activeClassName={styles.active} to="/catalogue/1" onClick={() => setShowNavBar(!showNavBar)}>
              {languages[activeLanguage].header.nav.auction}
              </NavLink>
            </li>
            <li>
              <NavLink activeClassName={styles.active} to="/auto-market" onClick={() => setShowNavBar(!showNavBar)}>
              {languages[activeLanguage].header.nav.autoMarket}
              </NavLink>
            </li>
            <li>
              <NavLink activeClassName={styles.active} to="/dealers" onClick={() => setShowNavBar(!showNavBar)}>
              {languages[activeLanguage].header.nav.dealer}
              </NavLink>
            </li>
            <li>
              <NavLink activeClassName={styles.active} to="/contact-us" onClick={() => setShowNavBar(!showNavBar)}>
              {languages[activeLanguage].header.nav.contact}
              </NavLink>
            </li>
          </ul>
          </OutsideClickHandler>
        </nav>
        )}
        <nav className={styles['navbar-desktop']}>
          <ul className={styles["navigation-list"]}>
            <li>
              <NavLink activeClassName={styles.active} to="/" exact>
              {languages[activeLanguage].header.nav.main}
              </NavLink>
            </li>
            <li>
              <NavLink activeClassName={styles.active} to="/catalogue/1">
              {languages[activeLanguage].header.nav.auction}
              </NavLink>
            </li>
            <li>
              <NavLink activeClassName={styles.active} to="/auto-market">
              {languages[activeLanguage].header.nav.autoMarket}
              </NavLink>
            </li>
            <li>
              <NavLink activeClassName={styles.active} to="/dealers">
              {languages[activeLanguage].header.nav.dealer}
              </NavLink>
            </li>
            <li>
              <NavLink activeClassName={styles.active} to="/contact-us">
              {languages[activeLanguage].header.nav.contact}
              </NavLink>
            </li>
          </ul>
        </nav>
        <div className={styles["header-controls"]}>
          <div className={styles["search-input"]}>
            <input
              type="text"
              onChange={searchOnChangeHandler}
              className={showSearchInput ? styles["header-form-control"] : styles["hide-search-input"]}
              placeholder={languages[activeLanguage].header.search}
            />
            {searchInputIsValid ? (
              <Link to={`/catalogue/1?search=${searchInputValue}`}><SearchIcon /></Link>
            ) : (
              <button className={styles["search-button"]} onClick={showInputFieldHandler}>
                <SearchIcon color="white" />
              </button>
            )}
          </div>
          <Bar barOnClick={barOnClickHandler} />
        </div>
      </div>
    </div>
  );
};

export default Header;
