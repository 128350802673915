const sellTypes = {
    EN: [
        {text: "Buy Now", value: "buy_now"},
        {text: "Copart", value: "copart"},
        {text: "IAAI", value: "iaai"}
    ],
    RU: [
        {text: "Купить сейчас", value: "buy_now"},
        {text: "Copart", value: "copart"},
        {text: "IAAI", value: "iaai"}
    ],
    GE: [
        {text: "იყიდე ახლავე", value: "buy_now"},
        {text: "Copart", value: "copart"},
        {text: "IAAI", value: "iaai"}
    ]
}

export default sellTypes