import { useState } from "react"
import AdminCarRequest from "./AdminCarRequest"
import AdminConsultations from "./AdminConsultations"
import AdminContact from "./AdminContact"
import AdminDealers from "./AdminDealers"
import AdminSubscribes from "./AdminSubscribes"
import styles from './Admin.module.css'

const Admin = () => {

    const [consultation, setConsultation] = useState(false)
    const [carRequest, setCarRequest] = useState(false)
    const [contact, setContact] = useState(false)
    const [dealers, setDealers] = useState(false)
    const [subscribe, setSubscribe] = useState(false)

    const setConsultationHandler = () => {
        setConsultation(true)
        setCarRequest(false)
        setContact(false)
        setDealers(false)
        setSubscribe(false)
    }

    const setCarRequestHandler = () => {
        setConsultation(false)
        setCarRequest(true)
        setContact(false)
        setDealers(false)
        setSubscribe(false)
    }

    const setContactHandler = () => {
        setConsultation(false)
        setCarRequest(false)
        setContact(true)
        setDealers(false)
        setSubscribe(false)
    }
    
    const setDealersHandler = () => {
        setConsultation(false)
        setCarRequest(false)
        setContact(false)
        setDealers(true)
        setSubscribe(false)
    }

    const setSubscribeHandler = () => {
        setConsultation(false)
        setCarRequest(false)
        setContact(false)
        setDealers(false)
        setSubscribe(true)
    }

    return(
        <div className={styles["admin-panel"]}>
            <ul className={styles['navbar-links']}>
                <li onClick={setConsultationHandler}>კონსულტაცია</li>
                <li onClick={setCarRequestHandler}>კონსულტაცია ავტომობილზე</li>
                <li onClick={setDealersHandler}>დილერის რეგისტრაცია</li>
                <li onClick={setContactHandler}>კონტაქტები</li>
                <li onClick={setSubscribeHandler}>გამოწერის მოთხოვნა</li>
            </ul>
            {
                consultation && <AdminConsultations />
            }
            {
                contact && <AdminContact />
            }
            {
                dealers && <AdminDealers />
            }
            {
                subscribe && <AdminSubscribes />
            }
            {
                carRequest && <AdminCarRequest />
            }

        </div>
    )
}

export default Admin