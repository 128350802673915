import { useSelector } from "react-redux";
import ImagesSlideDown from "./ImagesSlideDown";
import styles from "./VehicleImages.module.css";

const VehicleImages = () => {
  const vehicleData = useSelector((state) => state.vehiclesData);

  return (
    <div className={styles["images-slideshow-container"]}>
      <ImagesSlideDown />
      <div
        className={styles["main-image-container"]}
        style={{ backgroundImage: `url(${vehicleData.mainImage})` }}
      >
      </div>
    </div>
  );
};

export default VehicleImages;
