import axios from 'axios'
import { useState } from 'react'
import styles from './DealerRegForm.module.css'
import languages  from "../languages";
import { useSelector } from 'react-redux'

const DealerRegForm = () => {

    const activeLang = useSelector((state) => state.activeLanguage.lang);

    const [showSuccess, setShowSuccess] = useState(false)

    const [dealersForm, setDealersForm] = useState({
        name: null,
        city: null,
        phone: null,
        vehicles: null
    })

    const dealerNameKeyUpHandler = (e) => {
        setDealersForm((prevState) => {
            return {...prevState, name: e.target.value}
        })
    }

    const dealerCityKeyUpHandler = (e) => {
        setDealersForm((prevState) => {
            return {...prevState, city: e.target.value}
        })
    }

    const dealerNumberKeyUpHandler = (e) => {
        setDealersForm((prevState) => {
            return {...prevState, phone: e.target.value}
        })
    }

    const dealerCarsPerMonthKeyUpHandler = (e) => {
        setDealersForm((prevState) => {
            return {...prevState, vehicles: e.target.value}
        })
    }

    const formOnSubmitHandler = async () => {
        const formData = dealersForm;
        try{
            axios.post("/dealers/form", formData);
            setShowSuccess(true);
            setTimeout(() => {
                setShowSuccess(false)
            }, 4000);
        }catch(err){
            console.log(err)
        }
    }

    return (
        <section className={styles['dealers-registration-container']}>
            <h2 className={styles['section-title']}>{languages[activeLang].dealers.title}</h2>
            <div className={styles['registration-form-content']}>
                <div className={styles['registration-form-baner']}>
                    <div className={styles['text-baner-container']}>
                        <p className={styles['text-on-baner']}>{languages[activeLang].dealers.banner}</p>
                    </div>
                </div>
                <div className={styles['registration-form']}>
                    <div className={styles['fegistration-form-content']}>
                        <div className={styles['input-field-block']}>
                            <label className={styles['input-label']}>{languages[activeLang].dealers.name}</label>
                            <input type="text" placeholder={languages[activeLang].dealers.name} className={styles['form-control']} onKeyUp={dealerNameKeyUpHandler} />
                        </div>
                        <div className={styles['input-field-block']}>
                            <label className={styles['input-label']}>{languages[activeLang].dealers.city}</label>
                            <input type="text" placeholder={languages[activeLang].dealers.city} onKeyUp={dealerCityKeyUpHandler} className={styles['form-control']} />
                        </div>
                        <div className={styles['input-field-block']}>
                            <label className={styles['input-label']}>{languages[activeLang].dealers.number}</label>
                            <input type="text" placeholder={languages[activeLang].dealers.number} onKeyUp={dealerNumberKeyUpHandler} className={styles['form-control']} />
                        </div>
                        <div className={styles['input-field-block']}>
                            <label className={styles['input-label']}>{languages[activeLang].dealers.carPerMonth}</label>
                            <input type="text" placeholder={languages[activeLang].dealers.carPerMonth} onKeyUp={dealerCarsPerMonthKeyUpHandler} className={styles['form-control']} />
                        </div>
                        {showSuccess && <p style={{color: "green", fontSize: "14px"}}>{languages[activeLang].dealers.sent}</p>}
                        <button type='button' style={{cursor: "pointer"}} className={styles['submit-button']} onClick={formOnSubmitHandler}>{languages[activeLang].dealers.send}</button>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default DealerRegForm