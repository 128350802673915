import { useState } from "react";
import { Link } from "react-router-dom";
import axios from 'axios';
import Facebook from "../svg/Facebook";
import Instagram from "../svg/Instagram";
import Youtube from "../svg/Youtube";
import {useSelector} from "react-redux";
import styles from "./Footer.module.css";
import languages from "../languages";

const Footer = () => {

  const activeLang = useSelector((state) => state.activeLanguage.lang);
  const [showSuccess, setShowSuccess] = useState(false)

  const [subscribe, setSubscribe] = useState("")

  const subscribeOnChange = (e) => {
    setSubscribe(e.taget.value)
  }

  const subscribeHandler = async () => {
    try{
      await axios.post("/subscribe/form", {email: subscribe});
      setShowSuccess(true);
      setTimeout(() => {
        setShowSuccess(false)
      }, 4000);
    }catch(err){
      console.log(err)
    }
  }

  return (
    <footer className={styles["footer-container"]}>
      <div className={styles["footer-content"]}>
        <div className={styles["footer-navigation"]}>
          <h3 className={styles["footer-section-title"]}>{languages[activeLang].footer.navigation.title}</h3>
          <ul className={styles["footer-list"]}>
            <li>
              <Link to="/dealers"> {languages[activeLang].footer.navigation.frequency} </Link>
            </li>
            <li>
              <Link to="/dealers"> {languages[activeLang].footer.navigation.dealer} </Link>
            </li>
            <li>
              <a
                href="https://my.carexpress.ge"
                target="_blank"
                rel="noreferrer"
              >
                {languages[activeLang].footer.navigation.searchContainer}
              </a>
            </li>
            <li>
              <a
                href="https://my.carexpress.ge"
                target="_blank"
                rel="noreferrer"
              >
                {languages[activeLang].footer.navigation.cabinet}
              </a>
            </li>
          </ul>
        </div>
        <div className={styles["footer-our-services"]}>
          <h3 className={styles["footer-section-title"]}>{languages[activeLang].footer.services.title}</h3>
          <ul className={styles["footer-list"]}>
            <li>{languages[activeLang].footer.services.consultation}</li>
            <li>{languages[activeLang].footer.services.transportation}</li>
            <li>{languages[activeLang].footer.services.funding}</li>
          </ul>
        </div>
        <div className={styles["footer-contact"]}>
          <h3 className={styles["footer-section-title"]}>{languages[activeLang].footer.contact.title}</h3>
          <ul className={styles["footer-list"]}>
            <li>
              <a
                href="https://goo.gl/maps/o9ZRYtuCpLuwYXTQ9"
                target="_blank"
                rel="noreferrer"
              >
                {languages[activeLang].footer.contact.address}
              </a>
            </li>
            <li>
              <a href="mailto: info@carexpress.ge">{languages[activeLang].footer.contact.email}</a>
            </li>
            <li>
              <a href="tel: 032-2-424-100">{languages[activeLang].footer.contact.phone}</a>
            </li>
            <li className={styles["footer-social-networks"]}>
              <div className={styles["social-circle"]}>
                <Facebook
                  iconWidth="20"
                  borderColor="white"
                  iconColor="#717171"
                />
              </div>
              <div
                className={styles["social-circle"]}
                style={{ marginLeft: "10px" }}
              >
                <Instagram
                  iconWidth="20"
                  borderColor="white"
                  iconColor="#717171"
                />
              </div>
              <div
                className={styles["social-circle"]}
                style={{ marginLeft: "10px" }}
              >
                <Youtube
                  iconWidth="25"
                  borderColor="white"
                  iconColor="#717171"
                />
              </div>
            </li>
          </ul>
        </div>
        <div className={styles["footer-subscribe"]}>
          <h3 className={styles["footer-section-title"]}>
          {languages[activeLang].footer.subscription.subscribe}
          </h3>
          <div className={styles['subscribtion-form']}>
            <input
              type="email"
              placeholder={languages[activeLang].footer.subscription.email}
              className={styles["form-control"]}
              onChange={subscribeOnChange}
            />
            {showSuccess && <p style={{color: "green", fontSize: "14px"}}>{languages[activeLang].contactForm.Sent}</p>}
            <button className={styles["subscribtion-button"]} onClick={subscribeHandler} >{languages[activeLang].footer.subscription.button}</button>
          </div>
        </div>
      </div>
      <div className={styles['footer-hr']}/>
    </footer>
  );
};

export default Footer;
