import { useState, useReducer } from "react";
import styles from "./ConsultationForm.module.css";
import TermsPopup from "./TermsPopup";
import axios from 'axios';
import {useSelector} from "react-redux"
import languages from "../languages";

const reducer = (state, action) => {
  switch(action.type){
    case "name":{
      return {...state, name: action.payload}
    }
    case "number":{
      return {...state, number: action.payload}
    }
    case "agreed": {
      return {...state, agreed: action.payload}
    }
    default:{
      return {...state}
    }
  }
}

const ConsultationForm = ({consultationReference}) => {

  const currentLang = useSelector((state) => state.activeLanguage.lang);

  const [consultation, setConsultation] = useReducer(reducer, {
    name: "",
    number: "",
    agreed: null,
  });

  const [showSuccess, setShowSuccess] = useState(false)

  const [popupIsVisible, setPopupIsVisible] = useState(false)

  const [formIsValid, setFormIsValid] = useState(false);

  const nameInputKeypressHandler = (e) => {
    setConsultation({type: "name", payload: e.target.value})
  };

  const numberInputKeypressHandler = (e) => {
    setConsultation({type: "number", payload: e.target.value})
  };

  const termsAgreementClickHandler = (e) => {
    setConsultation({type: "agreed", payload: e.target.checked})
    if (
      consultation.name.trim() !== "" &&
      consultation.number.length > 7 &&
      e.target.checked === true
    ) {
      setFormIsValid(true);
    } else {
      setFormIsValid(false);
    }
  };

  const formOnSubmitHandler = async (e) => {
    e.preventDefault();
    try{
      await axios.post("/consultation/form", {name: consultation.name, phone: consultation.number});
      setShowSuccess(true)
      setTimeout(() => {
        setShowSuccess(false)
      }, 4000);
    }catch(err){
      console.log(err)
    }
  };

  const openModalForm = () => {
    setPopupIsVisible(true)
  }

  return (
    <section className={styles["consultation-form-container"]} id="consultation" ref={consultationReference}>
      {
        popupIsVisible && <TermsPopup closePopup={setPopupIsVisible} /> 
      }
      <div className={styles["consultation-form-content"]}>
        <div className={styles["form-text"]}>
          <h1 className={styles["form-control-title"]}>
            {languages[currentLang].contactForm.title[0]} <br /> {languages[currentLang].contactForm.title[1]}
          </h1>
          <p className={styles["form-control-text"]}>
            {languages[currentLang].contactForm.text[0]} <br /> {languages[currentLang].contactForm.text[1]}
          </p>
        </div>
        <div className={styles["form-container"]}>
          <form
            className={styles["consultation-form"]}
            onSubmit={formOnSubmitHandler}
          >
            <input
              type="text"
              placeholder={languages[currentLang].contactForm.name}
              onKeyUpCapture={nameInputKeypressHandler}
              className={styles["form-control"]}
            />
            <input
              type="text"
              placeholder={languages[currentLang].contactForm.number}
              onKeyUpCapture={numberInputKeypressHandler}
              className={styles["form-control"]}
            />
            <div className={styles["checkbox-content"]}>
              <input
                type="checkbox"
                id="agree-terms"
                onClick={termsAgreementClickHandler}
              />
              <label htmlFor="agree-terms" className={styles["form-label"]}>
                {languages[currentLang].contactForm.agreement[0]} <span className={styles['show-modal']} onClick={openModalForm}>{languages[currentLang].contactForm.agreement[1]}</span>
              </label>
              {showSuccess && <p style={{color: "green", fontSize: "14px"}}>{languages[currentLang].contactForm.sent}</p>}
            </div>
            {formIsValid ? (
              <button className={styles["submit-button"]} type="submit">
                {languages[currentLang].contactForm.send}
              </button>
            ) : (
              <button
                disabled
                className={styles["submit-button-disabled"]}
                type="submit"
              >
                {languages[currentLang].contactForm.send}
              </button>
            )}
          </form>
        </div>
      </div>
    </section>
  );
};

export default ConsultationForm;
