import { configureStore } from '@reduxjs/toolkit';
import formData from './formData';
import vehiclesData from './vehicle';
import activeLanguage from './activeLanguage';

const store = configureStore({
    reducer: {
        formData: formData,
        vehiclesData: vehiclesData,
        activeLanguage: activeLanguage
    }
});




export default store;