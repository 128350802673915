import { createSlice } from "@reduxjs/toolkit";

const initialHomeSearch = {
    mark: null,
    model: null,
    yearFrom: null,
    yearTo: null,
    priceFrom: null,
    priceTo: null,
    currency: "usd",
    condition: null,
    sellType: null
}

const formData = createSlice({
    name: 'formData',
    initialState: initialHomeSearch,
    reducers: {
        changeFormData(state, action){
            state[action.payload.name] = action.payload.value;
        },
        changeYearFrom(state, action){
            state.yearFrom = action.payload;
        },
        changeYearTo(state, action){
            state.yearTo = action.payload;
        },
        changePriceFrom(state, action) {
            state.priceFrom = action.payload
        },
        changePriceTo(state, action) {
            state.priceTo = action.payload
        },
        changeCurrency(state){
            state.currency = (state.currency === "usd") ? "gel" : "usd";
        }
    }
});

export const formDataActions = formData.actions;

export default formData.reducer;