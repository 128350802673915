import { useEffect, useState } from "react";
import Select from "../UI/Select";
import styles from "./SearchFormOnBaner.module.css";
import vehicles from "../data/models.json";
import modelsList from "../data/byMark.json";
import damages from "../data/damages";
import sellTypes from "../data/selltypes";
import { Link } from "react-router-dom";
import Prices from "../UI/Prices";
import languages from "../languages";
import { useSelector } from "react-redux";

const SearchFormOnBaner = ({margin}) => {
  let [searchLinkObject, setSearchLinkObject] = useState({currency: "usd", filter: true});
  const [modelsState, setModelsState] = useState([]);
  const activeLang = useSelector((state) => state.activeLanguage.lang);

  const [selected, setSelected] = useState({
    mark: languages[activeLang].search.mark,
    model: languages[activeLang].search.model,
    years: languages[activeLang].search.year,
    priceFrom: "",
    priceTo: "",
    currency: "",
    condition: languages[activeLang].search.condition,
    sellType: languages[activeLang].search.sellType,
  });

  useEffect(() => {
    let years = selected.years.replaceAll(" ", "");
    years = years.replaceAll("-", "");
    const yearsFrom = years.slice(0, 4);
    const yearsTo = years.slice(-4);
    if(yearsFrom !== "წელი" && yearsFrom !== "Year" && yearsFrom > 1900 && yearsFrom < 2200 ){
      setSearchLinkObject((prevState) => {
        return{...prevState, yearsFrom: yearsFrom}
      })
    }
    if(yearsTo > 1900 && yearsTo < 2200 ){
      setSearchLinkObject((prevState) => {
        return{...prevState, yearsTo: yearsTo}
      })
    }
  }, [selected.years])

  useEffect(() => {
    setSelected({
      mark: languages[activeLang].search.mark,
      model: languages[activeLang].search.model,
      years: languages[activeLang].search.year,
      priceFrom: "",
      priceTo: "",
      currency: "",
      condition: languages[activeLang].search.condition,
      sellType: languages[activeLang].search.sellType,
    });
  }, [activeLang]);

  const selectMark = (e) => {
    const selected = e.target.innerText;
    const showModels = modelsList.data.filter(
      (model) => model.attributes.text.replace("_", " ") === selected
    );
    const modelsResult = showModels.map((model) => {
      return { text: model.attributes.value, value: model.attributes.value };
    });
    setModelsState(modelsResult);

    setSearchLinkObject((prevState) => {
      return {
        ...prevState,
        mark: e.target.innerText.replace(" ", "_"),
      }
    });
  };

  const selectModel = (e) => {
    setSearchLinkObject((prevState) => {
      return {
        ...prevState,
        model: e.target.innerText.replace(" ", "_"),
      }
    });
  };

  const selectdamages = (e) => {
    setSearchLinkObject((prevState) => {
      return {
        ...prevState,
        condition: e.target.attributes["data-value"].value,
      }
    });
  };

  const selectSellType = (e) => {
    setSearchLinkObject((prevState) => {
      return {
        ...prevState,
        sellType: e.target.attributes["data-value"].value,
      }
    });
  };

  const isUsdHandler = (usd) => {
    setSearchLinkObject((prevState) => {
      return {...prevState, currency: usd ? "usd" : "gel"}
    })
  }
  const priceFromChangeHandler = (pr) => {
    setSearchLinkObject((prevState) => {
      return {...prevState, priceFrom: pr}
    })
  }
  const priceToChangeHandler = (pr) => {
    setSearchLinkObject((prevState) => {
      return {...prevState, priceTo: pr}
    })
  }

  return (
    <section className={styles["search-form-container"]} style={{marginTop: margin}}>
      <form className={styles["search-form"]}>
        <div className={styles["search-block"]}>
          <Select
            selectData={vehicles.maker_names?.sort((a, b) =>
              a.text > b.text ? 1 : -1
            )}
            mouseDownHandler={selectMark}
            selectedOption={selected.mark}
            selectName="mark"
            selectState={setSelected}
          />
          {/* <div className={styles["vertical-line"]} /> */}
          <Select
            selectData={modelsState}
            mouseDownHandler={selectModel}
            selectedOption={selected.model}
            selectName="model"
            selectState={setSelected}
          />
          {/* <div className={styles["vertical-line"]} /> */}
          <Select
            withTwoOption="true"
            // mouseDownHandler={selectYears}
            selectedOption={selected.years}
            selectName="years"
            selectState={setSelected}
          />
        </div>
        <div className={styles["search-block"]}>
          <Prices margin={true} priceFrom={priceFromChangeHandler} priceTo={priceToChangeHandler} changeCurrency={isUsdHandler} />
          {/* <div className={styles["vertical-line"]} /> */}
          <Select
            selectData={damages[activeLang]}
            selectedOption={selected.condition}
            mouseDownHandler={selectdamages}
            selectName="condition"
            selectState={setSelected}
          />
          {/* <div className={styles["vertical-line"]} /> */}
          <Select
            selectData={sellTypes[activeLang]}
            mouseDownHandler={selectSellType}
            selectedOption={selected.sellType}
            selectName="sellType"
            selectState={setSelected}
          />
        </div>
        <div className={styles["search-button"]}>
          <Link
            to={`/catalogue/1?${new URLSearchParams(
              searchLinkObject
            ).toString()}`}
            className={styles["submit-button"]}
          >
            {languages[activeLang].search.search}
          </Link>
        </div>
      </form>
    </section>
  );
};

export default SearchFormOnBaner;
