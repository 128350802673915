import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { vehicleDataActions } from "../../store/vehicle";
import styles from "./VehiclePopup.module.css";
import axios from 'axios'

const VehiclePopup = () => {

  const lang = {
    EN: {
      title: "Filling out the form",
      location: "Location",
      usa: "USA",
      name: "Name",
      phone: "Phone",
      type: "Purchase type",
      cach: "Cach",
      leasing: "Leasing",
      send: "Send",
      sent: "Application sent"
    },
    GE: {
      title: "შეავსეთ განაცხადი",
      location: "მდებარეობა",
      usa: "ამერიკა",
      name: "სახელი",
      phone: "ტელეფონი",
      type: "შესყიდვის ტიპი",
      cach: "ნაღდი ანგარიშსწორებით",
      leasing: "ლიზინგით",
      send: "გაგზავნა",
      sent: "თქვენი განაცხადი გაგზავნილია"
    }
  }

  const active = useSelector((state) => state.activeLanguage.lang)

  const [showSuccess, setShowSuccess] = useState(false)
  const vehicleData = useSelector((state) => state.vehiclesData);
  const dispatch = useDispatch();
  
  const closePopupHandler = () => {
    dispatch(vehicleDataActions.changePopupVisibility())
  }

  const [formData, setFormData] = useState({
    url: "",
    name: "",
    phone: "",
    sellType: ""
  })

  const nameOnChange = (e) => {
    setFormData((prevState) => {
      return {...prevState, name: e.target.value}
    })
  }
  const phoneOnChange = (e) => {
    setFormData((prevState) => {
      return {...prevState, phone: e.target.value}
    })
  }
  const sellTypeOnChange = (e) => {
    setFormData((prevState) => {
      return {...prevState, sellType: e.target.value}
    })
  }
  const sendRequestHandler = async (e) => {
    e.preventDefault();
    const data = {
      url: window.location.href,
      name: formData.name,
      phone: formData.phone,
      sellType: formData.sellType
    }
    try{
      await axios.post("/car-require", data);
      setShowSuccess(true);
      setTimeout(() => {
        setShowSuccess(false)
      }, 4000);
    }catch(err){
      console.log(err)
    }
  }

  return (
    <div className={styles["popup-modal"]}>
      <div className={styles["popup-background"]} onClick={closePopupHandler}></div>
      <div className={styles["popup-box-content"]}>
        <h3 className={styles["title-on-modal"]}>{lang[active].title}</h3>
        <div className={styles["vehicle-info"]}>
          <div
            className={styles["vehicle-image-on-modal"]}
            style={{ background: `url(${vehicleData.mainImage})` }}
          ></div>
          <div className={styles["vehicle-details"]}>
            <p className={styles["vehicle-model-on-modal"]}>
              {vehicleData &&
                `${vehicleData.data.attributes.name}`}
            </p>
            <p className={styles["vehicle-location"]}>
              {lang[active].location}: <span className={styles["location"]}>{lang[active].usa}</span>
            </p>
          </div>
        </div>
        <div className={styles["hr"]}></div>
        <form className={styles['require-car-form']} onSubmit={sendRequestHandler}>
            <div className={styles['form-input']}>
                <label className={styles['form-label']}>{lang[active].name}</label>
                <input type="text" placeholder={lang[active].name} onChange={nameOnChange} name="firstName" className={styles['form-control']} />
            </div>
            <div className={styles['form-input']}>
                <label className={styles['form-label']}>{lang[active].phone}</label>
                <input type="text" placeholder="+995" name="phone" onChange={phoneOnChange} className={styles['form-control']} />
            </div>
            <div className={styles['form-input']}>
                <label className={styles['form-label']}>{lang[active].type}</label>
                <select className={styles['form-control']} name="purchaseType" onChange={sellTypeOnChange}>
                    <option value="">{lang[active].type}</option>
                    <option value="cach">{lang[active].cach}</option>
                    <option value="leasing">{lang[active].leasing}</option>
                </select>
            </div>
            {showSuccess && <p style={{color: "green", fontSize: "14px", width: "90%", margin: "auto", marginTop: "10px"}}>{lang[active].sent}</p>}
            <div className={styles['submit-button-box']}>
            <button className={styles['submit-button']} >{lang[active].send}</button>
            </div>
        </form>
      </div>
    </div>
  );
};

export default VehiclePopup;
