import { useEffect } from "react";
import VehicleImages from "../components/Vehicle/VehicleImages";
import axios from "axios";
import { useLocation } from "react-router-dom";
import AuctionHeader from "../components/Vehicle/AuctionHeader";
import { useDispatch, useSelector } from "react-redux";
import { vehicleDataActions } from "../store/vehicle";
import RequireCar from "../components/Vehicle/RequireCar";
import AuctionServices from "../components/Vehicle/AuctionServices";
import VehicleDescription from "../components/Vehicle/VehicleDescription";
import VehiclePopup from "../components/Vehicle/VehiclePopup";

const AuctionVehicle = () => {
  window.scrollTo(0, 0);
  const vehicleData = useSelector((state) => state.vehiclesData)
  const lot = window.location.href.substring(
    window.location.href.lastIndexOf("/") + 1
  );

  const location = useLocation()

  const dispatch = useDispatch();

  useEffect(() => {
    axios.get(`/vehicle/${lot}`).then((response) => {
      dispatch(vehicleDataActions.changeData(response.data));
      dispatch(vehicleDataActions.changeMainImage(response.data.attributes.images[0]));
      dispatch(vehicleDataActions.changeImages(response.data.attributes.images));
    });
  }, [lot, dispatch, location]);

  return (
    <section>
      {
        vehicleData.popupIsOpened && <VehiclePopup />
      }
      <VehicleImages />
      <AuctionHeader />
      <RequireCar />
      <AuctionServices />
      <VehicleDescription />
    </section>
  );
};

export default AuctionVehicle;