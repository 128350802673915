import Consultation from "../svg/Consultation";
import Vehicle from "../svg/Vehicle";
import styles from "./AuctionServices.module.css";
import languages from "../languages";
import { useSelector } from "react-redux";

const AuctionServices = () => {
  const activeLang = useSelector((state) => state.activeLanguage.lang)
  return (
    <div className={styles["auction-services-container"]}>
      <div className={styles["service-content"]}>
        <Vehicle className={styles["icon"]} />
        <div className={styles["service-text-container"]}>
          <p className={styles["service-title"]}>{languages[activeLang].auctionPage.funding}</p>
          <p className={styles["service-description"]}>
            {languages[activeLang].auctionPage.fundingText}
          </p>
        </div>
      </div>
      <div className={styles["service-content"]}>
        <Consultation />
        <div className={styles["service-text-container"]}>
          <p className={styles["service-title"]}>{languages[activeLang].auctionPage.contactUs}</p>
          <p className={styles["service-description"]}>
            (032) 2-424-100 <br/>
            INFO@CAREXPRESS.GE <br/>
            {languages[activeLang].auctionPage.monday} - {languages[activeLang].auctionPage.friday}: 10am - 7pm <br/>
            {languages[activeLang].auctionPage.Saturday}: 10am - 1pm
          </p>
        </div>
      </div>
    </div>
  );
};

export default AuctionServices;
