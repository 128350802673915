const Gel = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15"
      height="17"
      viewBox="0 0 11 13"
      fill="white"
    >
      <path
        d="M2.432 7.352C2.432 5.464 2.96 4.328 3.856 3.752V7.56H4.976V3.352C5.152 3.336 5.344 3.32 5.536 3.32C5.728 3.32 5.92 3.336 6.096 3.352V7.56H7.216V3.752C8.112 4.328 8.64 5.528 8.64 7.56H10.192C10.192 4.888 9.04 3.064 7.216 2.408V0.952H6.096V2.152C5.92 2.136 5.728 2.12 5.536 2.12C5.344 2.12 5.152 2.136 4.976 2.152V0.952H3.856V2.408C2.032 3.08 0.88 4.872 0.88 7.192C0.88 9.544 1.824 11.016 3.312 11.768H1.616L1.44 13H9.136L9.312 11.768H6.096C4.128 11.768 2.432 10.248 2.432 7.352Z"
        fill="white"
      />
    </svg>
  );
};

export default Gel;
