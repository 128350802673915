import axios from 'axios';
import { useEffect, useState } from "react";
import styles from "./Admin.module.css"
import SimpleDataTable from '../../Common/DataTable';

const AdminSubscribes = () => {
    const [subscribe, setSubscribe] = useState([]);

    useEffect(() => {
      axios.get("/subscribe/form").then((response) => {
        setSubscribe(response.data.data);
      });
    }, []);
  
    const actions = {
      main: {

      },
      individual: {

      }
    }

    return (
      <SimpleDataTable items={subscribe} searchable={["email"]} actions={actions} />
    );
}

export default AdminSubscribes