import Offer from "../MainPage/Offer";
import styles from "./DealerServices.module.css";
import RegAsDealer from "../svg/RegAsDealer";
import EvacuatorIcon from "../svg/EvacuatorIcon";
import Cach from "../svg/Cach";
import languages  from "../languages";
import { useSelector } from 'react-redux'

const DealerServices = () => {

  const activeLang = useSelector((state) => state.activeLanguage.lang);

  return (
    <div className={styles["dealer-services-container"]}>
      <h2 className={styles["section-title"]}>
        {languages[activeLang].dealers.offers.title[0]} <br /> {languages[activeLang].dealers.offers.title[1]}
      </h2>
      <p className={styles["services-description"]}>
      {languages[activeLang].dealers.offers.text[0]} {" "}
        <br />
        {languages[activeLang].dealers.offers.text[1]}
      </p>
      <div className={styles["services-container"]}>
        <Offer
          offerTitle={languages[activeLang].dealers.offers.offer.codes.title}
          offerText={languages[activeLang].dealers.offers.offer.codes.text}
          bColor="#E9EFF5"
          icon={<RegAsDealer iconWidth="50" color="black" />}
        />
        <Offer
          offerTitle={languages[activeLang].dealers.offers.offer.transportation.title}
          offerText={languages[activeLang].dealers.offers.offer.transportation.text}
          bColor="#E9EFF5"
          icon={<EvacuatorIcon iconWidth="50" color="black" />}
        />
        <Offer
          offerTitle={languages[activeLang].dealers.offers.offer.funding.title}
          offerText={languages[activeLang].dealers.offers.offer.funding.text}
          bColor="#E9EFF5"
          icon={<Cach iconWidth="50" color="black" />}
        />
      </div>
    </div>
  );
};

export default DealerServices;
