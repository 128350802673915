const ConsultationIcon = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={`${props.iconWidth}px`}
      fill={(props.color)? props.color : "black" }
      viewBox="0 0 49.024 49.024"
    >
      <g
        id="vuesax_linear_messages"
        data-name="vuesax/linear/messages"
        transform="translate(-556 -314)"
      >
        <g id="messages" transform="translate(556 314)">
          <g id="Group" transform="translate(4.085 12.256)">
            <path
              id="Vector"
              d="M32.642,9.784v8.171a12.388,12.388,0,0,1-.082,1.532c-.47,5.515-3.718,8.252-9.7,8.252h-.817a1.645,1.645,0,0,0-1.307.654l-2.451,3.268a2.312,2.312,0,0,1-3.922,0l-2.451-3.268A1.886,1.886,0,0,0,10.6,27.74H9.784C3.268,27.74,0,26.126,0,17.955V9.784C0,3.8,2.758.552,8.252.082A12.389,12.389,0,0,1,9.784,0H22.858Q32.632,0,32.642,9.784Z"
              fill="none"
              stroke="#292d32"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="3"
            ></path>
          </g>
          <path
            id="Vector-2"
            data-name="Vector"
            d="M32.56,9.784v8.171q0,9.008-8.252,9.7a12.389,12.389,0,0,0,.082-1.532V17.955q0-9.774-9.784-9.784H1.532A12.389,12.389,0,0,0,0,8.252C.47,2.758,3.718,0,9.7,0H22.776Q32.55,0,32.56,9.784Z"
            transform="translate(12.338 4.085)"
            fill="none"
            stroke="#292d32"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="3"
          ></path>
          <path
            id="Vector-3"
            data-name="Vector"
            d="M0,0H49.024V49.024H0Z"
            fill="none"
            opacity="0"
          ></path>
          <path
            id="Vector-4"
            data-name="Vector"
            d="M.5.5H.514"
            transform="translate(27.072 26.566)"
            fill="none"
            stroke="#292d32"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="3"
          ></path>
          <path
            id="Vector-5"
            data-name="Vector"
            d="M.5.5H.514"
            transform="translate(19.922 26.566)"
            fill="none"
            stroke="#292d32"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="3"
          ></path>
          <path
            id="Vector-6"
            data-name="Vector"
            d="M.5.5H.514"
            transform="translate(12.773 26.566)"
            fill="none"
            stroke="#292d32"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="3"
          ></path>
        </g>
      </g>
    </svg>
  );
};

export default ConsultationIcon;
