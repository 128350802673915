import { Link } from "react-router-dom";
import styles from "./Vehicle.module.css";
import { useSelector } from "react-redux";

const Vehicle = ({vehicleData}) => {

  const activeLang = useSelector((state) => state.activeLanguage.lang)
  const lang = {
    EN: {
      km: "KM",
      buyNow: "Buy now",
      bid: "Current bid"
    },
    GE: {
      km: "კმ",
      buyNow: "იყიდე ახლავე",
      bid: "მიმდინარე ბიდი"
    }
  }

  return (
    <Link className={styles["vehicle-box"]} to={`/auction/${vehicleData.lotNumber}`}>
      <div
        className={styles["vehicle-image"]}
        style={{
          backgroundImage:
            `url(${decodeURI(vehicleData.image)})`,
        }}
      ></div>
      <div className={styles["vehicle-description"]}>
        <h3 className={styles['title-text']}>{`${vehicleData.name}`}</h3>
        <div className={styles["vehicle-info"]}>
          <p className={styles["vehicle-condition"]}>{vehicleData.damageDescription.replaceAll("_", " ")}</p>
          <div className={styles["circle"]}></div>
          <p className={styles["vehicle-millage"]}>{`${vehicleData.odometer} ${lang[activeLang].km}`}</p>
        </div>
        <p className={styles["vehicle-price"]}>{(vehicleData.buy_it_now_price !== "0.00")? `$${vehicleData.buy_it_now_price}` : `$${vehicleData.high_bid}`}</p>
        <div className={styles["sell-type"]}>{(vehicleData.buy_it_now_price !== "0.00")? lang[activeLang].buyNow : lang[activeLang].bid}</div>
      </div>
    </Link>
  );
};

export default Vehicle;
