const TransportationIcon = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={`${props.iconWidth}px`}
      fill={(props.color)? props.color : "black" }
      viewBox="0 0 43.801 43.801"
    >
      <g
        id="vuesax_linear_driving"
        data-name="vuesax/linear/driving"
        transform="translate(-371 -188)"
      >
        <g id="driving" transform="translate(371 188)">
          <g id="Group" transform="translate(8.657 3.65)">
            <path
              id="Vector"
              d="M15.239,0H5.931C2.646,0,1.916,1.643,1.5,3.668L0,10.823H21.171l-1.5-7.154C19.254,1.643,18.524,0,15.239,0Z"
              transform="translate(2.658)"
              fill="none"
              stroke="#292d32"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2.5"
            />
            <g id="Group-2" data-name="Group" transform="translate(0 10.804)">
              <path
                id="Vector-2"
                data-name="Vector"
                d="M26.457,11.68a2.659,2.659,0,0,1-2.683,2.884H21.292c-1.424,0-1.624-.6-1.88-1.369l-.274-.785c-.365-1.077-.6-1.807-2.519-1.807H9.849c-1.9,0-2.19.821-2.519,1.807l-.274.785c-.256.748-.456,1.369-1.88,1.369H2.695A2.659,2.659,0,0,1,.012,11.68L.76,3.614C.942,1.624,1.326,0,4.793,0H21.675c3.468,0,3.851,1.624,4.033,3.614Z"
                transform="translate(0)"
                fill="none"
                stroke="#292d32"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2.5"
              />
            </g>
            <path
              id="Vector-3"
              data-name="Vector"
              d="M1.332,0H0"
              transform="translate(1.326 6.844)"
              fill="none"
              stroke="#292d32"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2.5"
            />
            <path
              id="Vector-4"
              data-name="Vector"
              d="M1.332,0H0"
              transform="translate(23.829 6.844)"
              fill="none"
              stroke="#292d32"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2.5"
            />
            <path
              id="Vector-5"
              data-name="Vector"
              d="M0,0H3.96"
              transform="translate(5.304 16.115)"
              fill="none"
              stroke="#292d32"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2.5"
            />
            <path
              id="Vector-6"
              data-name="Vector"
              d="M0,0H3.96"
              transform="translate(17.222 16.115)"
              fill="none"
              stroke="#292d32"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2.5"
            />
          </g>
          <path
            id="Vector-7"
            data-name="Vector"
            d="M0,0V1.825"
            transform="translate(21.901 31.026)"
            fill="none"
            stroke="#6a6a6a"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="3"
          />
          <path
            id="Vector-8"
            data-name="Vector"
            d="M0,0V1.825"
            transform="translate(21.901 38.326)"
            fill="none"
            stroke="#6a6a6a"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="3"
          />
          <path
            id="Vector-9"
            data-name="Vector"
            d="M1.825,0,0,7.3"
            transform="translate(3.65 32.851)"
            fill="none"
            stroke="#6a6a6a"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="3"
          />
          <path
            id="Vector-10"
            data-name="Vector"
            d="M0,0,1.825,7.3"
            transform="translate(38.326 32.851)"
            fill="none"
            stroke="#6a6a6a"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="3"
          />
          <path
            id="Vector-11"
            data-name="Vector"
            d="M0,0H43.8V43.8H0Z"
            fill="none"
            opacity="0"
          />
        </g>
      </g>
    </svg>
  );
};

export default TransportationIcon;
