const BuyIcon = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.0"
      width={`${props.iconWidth}px`}
      viewBox="0 0 762.000000 1280.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <metadata>
        Created by potrace 1.15, written by Peter Selinger 2001-2017
      </metadata>
      <g
        transform="translate(0.000000,1280.000000) scale(0.100000,-0.100000)"
        fill="#000000"
        stroke="none"
      >
        <path d="M590 12784 c-224 -48 -411 -194 -510 -399 -29 -59 -55 -131 -64 -177 -14 -69 -16 -663 -16 -5808 0 -5145 2 -5739 16 -5808 47 -233 221 -444 444 -536 145 -59 -80 -56 3350 -56 3430 0 3205 -3 3350 56 168 70 301 195 380 359 29 59 55 131 64 177 14 69 16 663 16 5808 0 5145 -2 5739 -16 5808 -47 233 -221 444 -444 536 -145 59 81 56 -3355 55 -2654 0 -3160 -3 -3215 -15z m4050 -399 l0 -65 -830 0 -830 0 0 65 0 65 830 0 830 0 0 -65z m2070 -466 c61 -29 120 -85 147 -139 17 -33 18 -277 21 -5038 l2 -5003 -21 -50 c-27 -63 -99 -128 -170 -154 -54 -20 -79 -20 -2879 -20 -2800 0 -2825 0 -2879 20 -70 26 -138 87 -167 150 l-24 50 0 4991 c0 3708 3 5000 11 5025 16 45 66 112 107 140 18 12 57 31 85 40 50 18 184 18 2882 16 l2830 -2 55 -26z m-3934 -11226 c-2 -2 -129 59 -282 135 l-279 139 280 141 280 140 3 -276 c1 -151 0 -277 -2 -279z m1432 275 l-3 -283 -282 -3 -283 -2 0 285 0 285 285 0 285 0 -2 -282z m1134 253 c131 -66 190 -216 138 -353 -59 -158 -245 -228 -397 -149 -175 90 -197 348 -40 471 80 63 208 76 299 31z" />
      </g>
    </svg>
  );
};

export default BuyIcon;
