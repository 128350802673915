import styles from "./ContactUsItem.module.css";

const ContactUsItem = (props) => {
  return (
    <div className={styles["contact-info"]}>
      {props.icon}
      <div className={styles["info-text"]}>
        <p className={styles["info-title"]}>{props.title}</p>
        <a className={styles["info-content"]} href={props.htmlHref} target="_blank" rel="noreferrer">
          {props.itemText}
        </a>
      </div>
    </div>
  );
};

export default ContactUsItem;
