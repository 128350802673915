import styles from "./Baner.module.css";
import BanerText from "./BanerText";
import SocialMedia from "./SocialMedia";

const Baner = ({consultationReferences}) => {
  return (
    <section className={styles["baner-container"]}>
      <div className={styles['baner-content']}>
        <BanerText consultationReference={consultationReferences} />
        <SocialMedia />
      </div>
    </section>
  );
};

export default Baner;
