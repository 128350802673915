const Phone = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
    >
      <path
        d="M13.7402 7.26077C16.6296 7.26077 17.8621 8.48478 17.8621 11.3544H20.61C20.61 6.95375 18.1712 4.53166 13.7402 4.53166V7.26077ZM18.4419 14.688C18.1779 14.4497 17.831 14.3226 17.4744 14.3336C17.1178 14.3445 16.7794 14.4927 16.5307 14.7467L13.2428 18.1049C12.4514 17.9548 10.8603 17.4622 9.22252 15.8397C7.58474 14.2118 7.08874 12.6276 6.94172 11.847L10.3203 8.5803C10.5765 8.33349 10.7258 7.99739 10.7369 7.64313C10.7479 7.28887 10.6197 6.94425 10.3794 6.6822L5.30257 1.13801C5.06218 0.875139 4.72808 0.715687 4.37122 0.69352C4.01436 0.671354 3.66286 0.788219 3.39136 1.01929L0.409833 3.55873C0.172287 3.7955 0.0305044 4.11063 0.0113792 4.44433C-0.00923043 4.78547 -0.402188 12.8664 5.90712 19.1351C11.4113 24.6002 18.3059 25 20.2047 25C20.4823 25 20.6526 24.9918 20.698 24.9891C21.0339 24.9704 21.3511 24.829 21.5883 24.592L24.1439 21.6295C24.3768 21.3601 24.4947 21.0111 24.4726 20.6567C24.4505 20.3023 24.2902 19.9704 24.0258 19.7314L18.4419 14.688Z"
        fill="#2D2D2D"
      />
    </svg>
  );
};

export default Phone