import { useEffect, useState } from 'react'
import Vehicle from './Vehicle'
import styles from './VehiclesList.module.css'
import axios from 'axios'
import Loader from '../UI/Loader'
import languages from '../languages'
import { useSelector } from 'react-redux'

const VehiclesList = () => {
    const activeLang = useSelector((state) => state.activeLanguage.lang)
    const [vehicles, setVehicles] = useState([])

    useEffect(() => {
        axios
            .get('/vehicles/main-page')
            .then((response) => {
                response.data.cars.forEach((img) => img.image = img.image.replaceAll("\\", "/"))
                setVehicles(response.data.cars)
            })
    }, [])

    return (
        <section className={styles['vehicles-list-container']}>
            <h2 className={styles['vehicles-list-title']}>{languages[activeLang].mainCatalog.title}</h2>
            <div className={styles['vehicles-list']}>
                {
                    (vehicles.length > 0) ? (
                        vehicles.map((vehicle) => <Vehicle key={vehicle.lotNumber} vehicleData={vehicle} />)
                    ) : (
                        <Loader />
                    )
                }
            </div>
            <button className={styles['autocatalogue-button']}>{languages[activeLang].mainCatalog.button}</button>
        </section>
    )
}

export default VehiclesList