import React, {useRef} from "react";
import AditionalServices from "../components/MainPage/AditionalServices";
import Baner from "../components/MainPage/Baner";
import ConsultationForm from "../components/MainPage/ConsultationForm";
import OurOffers from "../components/MainPage/OurOffers";
import SearchFormOnBaner from "../components/MainPage/SearchFormOnBaner";
import VehiclesList from "../components/MainPage/VehiclesList";

const MainPage = () => {
    window.scrollTo(0, 0);

    const consultationRef = useRef();

    return(
        <div>
            <Baner consultationReferences={consultationRef} />
            <SearchFormOnBaner margin="-80px" />
            <OurOffers />
            <VehiclesList />
            <AditionalServices />
            <ConsultationForm consultationReference={consultationRef} />
        </div>
    )
}

export default MainPage;