import React from "react";
import VehiclesList from "../components/Auction/VehiclesList";
import SearchFormOnBaner from "../components/MainPage/SearchFormOnBaner";

const CopartAuction = () => {
    window.scrollTo(0, 0);
    return(
        <div>
            <SearchFormOnBaner margin="60px" />
            <VehiclesList />
        </div>
    )
}

export default CopartAuction;