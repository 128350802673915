import axios from "axios";
import { useEffect, useState } from "react";
import styles from "./Admin.module.css"
import SimpleDataTable from "../../Common/DataTable";

const AdminContact = () => {
    const [contact, setContact] = useState([]);

    useEffect(() => {
      axios.get("/contact/form").then((response) => {
        setContact(response.data.data);
      });
    }, []);
    
    const actions = {
      main: {

      },
      individual: {

      }
    }

    return (
      <>
      <SimpleDataTable items={contact} searchable={["message", "email", "subject", "name"]} actions={actions} />
      </>
    );
}

export default AdminContact