const Location = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="26"
      height="33"
      viewBox="0 0 26 33"
      fill="none"
    >
      <path
        d="M13 0C9.55344 0.00405484 6.2492 1.37123 3.81211 3.80163C1.37502 6.23203 0.00407984 9.52719 1.38307e-05 12.9643C-0.00411413 15.7731 0.915899 18.5057 2.61892 20.7429C2.61892 20.7429 2.97347 21.2084 3.03137 21.2756L13 33L22.9734 21.2697C23.0254 21.2072 23.3811 20.7429 23.3811 20.7429L23.3823 20.7393C25.0844 18.5031 26.004 15.7718 26 12.9643C25.9959 9.52719 24.625 6.23203 22.1879 3.80163C19.7508 1.37123 16.4466 0.00405484 13 0ZM13 17.6786C12.065 17.6786 11.1511 17.4021 10.3737 16.8841C9.59628 16.3661 8.99037 15.6298 8.63257 14.7684C8.27478 13.9069 8.18116 12.9591 8.36357 12.0446C8.54597 11.1301 8.9962 10.2901 9.65732 9.63078C10.3184 8.97148 11.1608 8.52248 12.0778 8.34058C12.9948 8.15868 13.9453 8.25204 14.809 8.60885C15.6728 8.96567 16.4111 9.56991 16.9306 10.3452C17.45 11.1204 17.7273 12.0319 17.7273 12.9643C17.7257 14.2141 17.2272 15.4123 16.341 16.2961C15.4548 17.1798 14.2533 17.677 13 17.6786Z"
        fill="#2D2D2D"
      />
    </svg>
  );
};

export default Location