import styles from './AdminAuth.module.css'

const AutoMarket = () => {
    window.scrollTo(0, 0);
    return(
        <div className={styles['auto-market-container']}>
            <p>Coming soon</p>
        </div>
    )
}

export default AutoMarket