import Offer from "./Offer";
import styles from "./OurOffers.module.css";
import BuyIcon from "../svg/BuyIcon";
import ConsultationIcon from "../svg/ConsultationIcon";
import FundingIcon from "../svg/FundingIcon";
import TransportationIcon from "../svg/TransportationIcon";
import languages from "../languages";
import { useSelector } from "react-redux";

const OurOffers = () => {

  const activeLang = useSelector((state) => state.activeLanguage.lang)

  return (
    <section className={styles["our-offers-container"]}>
      <h2 className={styles["our-offers-title"]}>{languages[activeLang].offers.ourOffers}</h2>
      <div className={styles['offers-list']}>
        <Offer
          offerTitle={languages[activeLang].offers.consultation.title}
          offerText={languages[activeLang].offers.consultation.text}
          bColor="#E9EFF5"
          icon={<ConsultationIcon iconWidth="50" color="black"/>}
        />
        <Offer
          offerTitle={languages[activeLang].offers.buy.title}
          bColor="#FFEDF0"
          offerText={languages[activeLang].offers.buy.text}
          icon={<BuyIcon iconWidth="25" color="black" />}
        />
        <Offer
          offerTitle={languages[activeLang].offers.funding.title}
          bColor="#EBF3ED"
          offerText={languages[activeLang].offers.funding.text}
          icon={<FundingIcon iconWidth="49" color="black" />}
        />
        <Offer
          offerTitle={languages[activeLang].offers.transportation.title}
          bColor="#FAF1E8"
          offerText={languages[activeLang].offers.transportation.text}
          icon={<TransportationIcon iconWidth="49" color="black" />}
        />
      </div>
    </section>
  );
};

export default OurOffers;
