import axios from "axios";
import { useEffect, useState } from "react";
import styles from "./Admin.module.css"
import SimpleDataTable from "../../Common/DataTable";

const AdminCarRequest = () => {
  const [carRequests, setCarRequests] = useState([]);

  useEffect(() => {
    axios.get("/car-require").then((response) => {
      setCarRequests(response.data.data);
    });
  }, []);
  const actions = {
    main: {

    },
    individual: {

    }
  }
  return (
    <>
    <SimpleDataTable items={carRequests} actions={actions} searchable={["name", "phone", "selltype", "url"]} />
    </>
  );
};

export default AdminCarRequest;