import React from "react";
import languages from "../languages";
import styles from "./BanerText.module.css";
import { useSelector } from "react-redux";

const BanerText = ({ consultationReference }) => {

  const activeLang = useSelector((state) => state.activeLanguage.lang);
  const scrollToConsultation = () => {
    consultationReference.current.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <div className={styles["baner-text-container"]}>
      <h1 className={styles["baner-title"]}>
        {languages[activeLang].banner.title}
      </h1>
      <h4 className={styles["baner-text"]}>
      {languages[activeLang].banner.subTitle}
      </h4>
      <button onClick={scrollToConsultation} className={styles["consultation-button"]}>
      {languages[activeLang].banner.button}
      </button>
    </div>
  );
};

export default BanerText;
