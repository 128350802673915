import React, { Fragment } from "react";
import DealerRegForm from "../components/Dealers/DealerRegForm";
import DealerServices from "../components/Dealers/DealerServices";
import FrequencyQuestions from "../components/Dealers/FrequencyQuestions";

const Dilers = () => {
  window.scrollTo(0, 0);
  return (
    <Fragment>
      <DealerRegForm />
      <DealerServices />
      <FrequencyQuestions />
    </Fragment>
  );
};

export default Dilers;
