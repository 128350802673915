import styles from './FrequencyQuestions.module.css'
import Questions from './Questions'
import languages from '../languages'
import { useSelector } from 'react-redux'

const FrequencyQuestions = () => {

    const activeLang = useSelector((state) => state.activeLanguage.lang);

    return (
        <div className={styles['frequency-questions-container']}>
            <h2 className={styles['section-title']}>{languages[activeLang].dealers.qustions.title}</h2>
            <Questions question={languages[activeLang].dealers.qustions.question[0].question} answer={languages[activeLang].dealers.qustions.question[0].answer} />
            <Questions question={languages[activeLang].dealers.qustions.question[1].question} answer={languages[activeLang].dealers.qustions.question[1].answer} />
            <Questions question={languages[activeLang].dealers.qustions.question[2].question} answer={languages[activeLang].dealers.qustions.question[2].answer} />
            <Questions question={languages[activeLang].dealers.qustions.question[3].question} answer={languages[activeLang].dealers.qustions.question[3].answer} />
        </div>
    )
}

export default FrequencyQuestions