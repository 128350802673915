import { useEffect, useState } from "react";
import styles from "./DropdownWithTwoOption.module.css";
import years from "../data/years";
import yearsReverse from "../data/yearsReverse";
import { useDispatch, useSelector } from "react-redux";
import { formDataActions } from "../../store/formData";
import languages from "../languages";


const DropdownWithTwoOption = (props) => {
  const activeLang = useSelector((state) => state.activeLanguage.lang);
  const [selectYears, setSelectYears] = useState({from: languages[activeLang].search.from, to: languages[activeLang].search.to});
  const formData = useSelector((state) => state.formData);
  const dispatch = useDispatch();

  useEffect(() => {
    if(formData.yearFrom !== languages[activeLang].search.from){
      setSelectYears((prevState) => {
        return {...prevState, from: parseInt(parseInt(formData.yearFrom))}
      })
    }

    if(formData.yearTo !== languages[activeLang].search.to){
      setSelectYears((prevState) => {
        return {...prevState, to: parseInt(parseInt(formData.yearTo))}
      })
    }
  }, [formData,activeLang])

  const yearFromClickHandler = (e) => {
    if(selectYears.from === parseInt(e.target.outerText)){
      setSelectYears((prevState) => {
        return {...prevState, from: languages[activeLang].search.from}
      })
    }else{
      setSelectYears((prevState) => {
        return {...prevState, from: parseInt(e.target.outerText)}
      })
    }
  }

  const yearToClickHandler = (e) => {
    if(selectYears.to === parseInt(e.target.outerText)){
      setSelectYears((prevState) => {
        return {...prevState, to: languages[activeLang].search.to}
      })
    }else{
      setSelectYears((prevState) => {
        return {...prevState, to: parseInt(e.target.outerText)}
      })
    }
  }

  const chooseYearsFilter = (e) => {
    dispatch(formDataActions.changeYearFrom(selectYears.from.toString()));
    dispatch(formDataActions.changeYearTo(selectYears.to.toString()));
    if(selectYears.from === languages[activeLang].search.from && selectYears.to === languages[activeLang].search.to){
      props.selectOption((prevState) => {
        return {...prevState, years: languages[activeLang].search.year}
      })
    }else{
      props.selectOption((prevState) => {
        return {...prevState, years: `${selectYears.from} - ${selectYears.to}`}
      })
    }
    props.closeSelect(false)
  }

  return (
    <div className={styles["select-dropdown-list"]}>
      <ul className={styles["dropdown-list-one"]} area-label={languages[activeLang].search.from}>
        <p className={styles["list-header"]}>{languages[activeLang].search.from}</p>
        <div className={styles.hr} />
        {years.map((year) => {
          return <li key={year} onClick={yearFromClickHandler} className={(selectYears.from === year) ? styles['active'] : styles['inactive'] } >{year}</li>;
        })}
      </ul>
      <div className={styles["vertical-line"]}></div>
      <ul className={styles["dropdown-list-two"]} area-label={languages[activeLang].search.to}>
        <p className={styles["list-header"]}>{languages[activeLang].search.to}</p>
        <div className={styles.hr} />
        {yearsReverse.map((year) => {
          return <li key={year + 100} onClick={yearToClickHandler} className={(selectYears.to === year) ? styles['active'] : styles['inactive'] } >{year}</li>;
        })}
      </ul>
      <div className={styles["choose"]}>
        <button type="button" className={styles["choose-button"]} onClick={chooseYearsFilter}>{languages[activeLang].search.choose}</button>
      </div>
    </div>
  );
};

export default DropdownWithTwoOption;
