import axios from 'axios';
import { useEffect, useState } from "react";
import styles from "./Admin.module.css"
import SimpleDataTable from '../../Common/DataTable';

const AdminConsultations = () => {
    const [carRequests, setCarRequests] = useState([]);

    useEffect(() => {
      axios.get("/consultation/form").then((response) => {
        setCarRequests(response.data.data);
      });
    }, []);

    const actions = {
      main: {

      },
      individual: {

      }
    }
  
    return (
      <SimpleDataTable items={carRequests} searchable={["name", "phone"]} actions={actions} />
    )
}

export default AdminConsultations