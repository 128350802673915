import styles from "./ImagesSlideDown.module.css";
import { useDispatch, useSelector } from "react-redux";
import { vehicleDataActions } from "../../store/vehicle";

const ImagesSlideDown = () => {
  const vehicle = useSelector((state) => state.vehiclesData)
  const dispatch = useDispatch();
  
  const imageOnClickHandler = (e) => {
    dispatch(vehicleDataActions.changeMainImage(e.target.currentSrc))
  }

  return (
    <div className={styles["slide-down-images-container"]}>
      <div className={styles["slide-down-images-content"]}>
        {vehicle.images.length > 0 && (
          vehicle.images.map((image, k) => {  
            return <img key={k * 5} data-id={k} src={image} alt="vehicle" onClick={imageOnClickHandler} />;
          })
        )}
      </div>
    </div>
  );
};

export default ImagesSlideDown;
