import UserIcon from '../svg/UserIcon'
import styles from './RequireCar.module.css'
import { useDispatch } from 'react-redux'
import { vehicleDataActions } from '../../store/vehicle'
import languages from "../languages";
import { useSelector } from "react-redux";

const RequireCar = () => {
    const activeLang = useSelector((state) => state.activeLanguage.lang)
    const dispatch = useDispatch();

    const openRequireFormModalHandler = () => {
        dispatch(vehicleDataActions.changePopupVisibility())
    }

    return (
        <div className={styles['car-require-container']}>
            <div className={styles['manager-section']}>
                <UserIcon iconWidth="22" iconHeight="22" />
                <p className={styles['manager-name']}>{languages[activeLang].auctionPage.manager}</p>
                <div className={styles['active']}></div>
            </div>
            <button className={styles['car-require-button']} onClick={openRequireFormModalHandler}>
            {languages[activeLang].auctionPage.buy}
            </button>
        </div>
    )
}

export default RequireCar